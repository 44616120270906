var getQueryParam = function getQueryParam(name) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

var docCookies = {
  getItem: function (sKey) {
    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  },
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
    var sExpires = "";
    if (vEnd) {
      switch (vEnd.constructor) {
      case Number:
        sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
        break;
      case String:
        sExpires = "; expires=" + vEnd;
        break;
      case Date:
        sExpires = "; expires=" + vEnd.toUTCString();
        break;
      }
    }
    document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
    return true;
  },
  removeItem: function (sKey, sPath, sDomain) {
    if (!sKey || !this.hasItem(sKey)) { return false; }
    document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + ( sDomain ? "; domain=" + sDomain : "") + ( sPath ? "; path=" + sPath : "");
    return true;
  },
  hasItem: function (sKey) {
    return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
  }
};

const utms = {};
const source = getQueryParam('utm_source'),
  medium = getQueryParam('utm_medium'),
  campaign = getQueryParam('utm_campaign'),
  content = getQueryParam('utm_content')

let referrer = ''
if(window.document.referrer) {
  try{
    const url = new URL(window.document.referrer)
    if(url.hostname !== window.location.hostname) {
      referrer = window.document.referrer
    }
  }catch(e){}
}


if(source) {
  utms.s = source;
}
if(medium) {
  utms.m = medium;
}
if(campaign) {
  utms.c = campaign;
}
if(content) {
  utms.co = content;
}
if(referrer) {
  utms.r = referrer
}

if(Object.keys(utms).length > 0) {
  var parts = window.location.hostname.split('.'),
    domain = '.' + parts[parts.length - 2] + '.' + parts[parts.length - 1];

  docCookies.setItem('utms', JSON.stringify(utms), Infinity, '/', domain);
}

export const getCodes = () => {
  const val = docCookies.getItem('utms');
  if(val) {
    const utms = JSON.parse(val);
    return {
      source: utms.s,
      medium: utms.m,
      campaign: utms.c,
      content: utms.co,
      referrer: utms.r
    };
  }
  return {}
}

