import { Cookies } from '@shared/helpers';
import $ from 'jquery';

const exportLocation = () => {
  var key = 'location';
  var deferred = $.Deferred();

  if (Cookies.get(key)) {
    let cookieData = {}
    try {
      cookieData = JSON.parse(Cookies.get(key));
    } catch(e) { }

    deferred.resolve(cookieData);
    return deferred.promise();
  }

  $.get('/geo.php')
    .done((response) => {
      Cookies.set(key, JSON.stringify(response), { path: '/', expires: 30 });
      deferred.resolve(response);
    })
    .fail((jqXHR, textStatus, error) => deferred.reject({ error, textStatus }));

  return deferred.promise();
}

export default exportLocation
