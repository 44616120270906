import Backbone from 'backbone'
import $ from 'jquery'
import Velocity from 'velocity-animate'

export default Backbone.View.extend({
  el: 'body',

  events: {
    'click .js-contact-trigger': 'showContactForm',
    'click .js-search-articles': 'searchKnowledgeBase',
    'click .js-support-video-trigger': 'openSupportVideo',
    'click .js-support-video-close': 'closeSupportVideo',
    'keydown .js-search-input': 'handleEnter'
  },

  initialize: function() {
    $('#supportForm').submit(function(evt) {
      evt.preventDefault();
      var form = $(this);
      var data = form.serializeArray();
      var button = $('.js-submit');
      button.addClass('btn--loading').prop('disabled', true);


      $.ajax({
        url: '/apiv1/site/contact/form',
        method: 'POST',
        data: data,
        success: function(response) {
          button.removeClass('btn--loading').prop('disabled', false);
          form[0].reset();
          Velocity($('.js-thanks'), 'fadeIn');
        },
        error: (response) => {
          let res = 'shared.default_error'.t()
          if(response && response.responseJSON && response.responseJSON.message) {
            res = response.responseJSON.message.t({ sub: 'support@' + window.location.hostname.replace('www', '') });
          }
          button.removeClass('btn--loading').prop('disabled', false);
          window.alert(res);
        }
      });
    });
  },

  showContactForm: function() {
    this.$('.js-categories, .js-contact-trigger').hide();
    this.$('.js-contact, .js-cancel, .js-submit').show();
    this.$('.js-contact [name]:not([disabled], .hidden)')[0].focus();
  },

  searchKnowledgeBase: function() {
    var query = this.$('.js-search-input').val();
    if (query.length > 0) {
      window.open('https://typingcom.helpscoutdocs.com/search?query=' + query)
    }
  },

  handleEnter: function(e) {
    if (e.which === 13) {
      this.searchKnowledgeBase();

      return false;
    }
  },

  openSupportVideo: function() {
    const supportVideoOverlay = this.$('.js-support-video-overlay'),
      supportVideo = document.querySelector('.js-support-video'); // need js dom element to trigger pause/play

    Velocity(
      supportVideoOverlay,
      {opacity: 1},
      {
        display: 'flex',
        duration: 350,
        easing: 'easeIn',
        queue: false,
        complete: () => {
          Velocity(
            supportVideo,
            {opacity: 1, translateY: [0, '10%']},
            {
              display: 'block',
              duration: 125,
              easing: 'easeIn',
              queue: false,
            })
        }
      }
    )
  },

  getVideoProgress: function(elem) {
    const videoLength = elem.duration;

    return Math.round((elem.currentTime/videoLength)*100);
    
  },

  closeSupportVideo: function() {
    const supportVideoOverlay = this.$('.js-support-video-overlay'),
      supportVideo = document.querySelector('.js-support-video'); // need js dom element to trigger pause/play

    Velocity(
      supportVideoOverlay,
      {opacity: 0},
      {
        display: 'none',
        duration: 50,
        easing: 'easeOut',
        queue: false,
        complete: () => {
          Velocity(
            supportVideo,
            {opacity: 0},
            {
              display: 'none',
              duration: 0,
              queue: false,
            }
          )
        }
      }
    )
  }

})
