import $ from 'jquery'
import Backbone from 'backbone'
import Notice from '@shared/notice'
import FormValidator from './form_validator'
import { extend, findIndex, compact } from 'lodash-es'
import CodeMirror from 'codemirror';
import 'codemirror/mode/xml/xml'
import 'codemirror/mode/htmlmixed/htmlmixed'
import 'codemirror/mode/css/css'
import 'codemirror/mode/sass/sass'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/addon/fold/foldgutter'

/**
 * If set (with formSelector) then this will automatically have form validation functionality built in
 * @type {Backbone.Model}
 */
Backbone.View.prototype.modelClass = null

Backbone.View.prototype.views = null

/**
 * Selector for the form element
 * @type {string|jQuery}
 */
Backbone.View.prototype.formSelector = ''

/**
 * If the form submits successfully, this can automatically show a Notice
 * @type {string}|{function}
 */
Backbone.View.prototype.successNotice = ''

/**
 * If the form should submit as a file upload
 * @type {boolean}
 */
Backbone.View.prototype.fileUpload = false

/**
 * Simple initialize function. Don't use if you don't want to
 * @param {object} options
 */
Backbone.View.prototype.initialize = function(options) {
  this.views = {};

  this.delegate('click', '.form-fieldset .js-show', this.togglePasswordDisplay.bind(this));

  extend(this, options);

  if (this.modelClass && !this.model) {
    this.model = new this.modelClass(this.data); // data comes from options
  }
}

/**
 * Assign a child view
 * @param {string|jQuery} selector
 * @param {Backbone.View|*} view
 * @param {boolean} [append=false] Should append to the view vs setElement
 * @returns {Backbone.View}
 */
Backbone.View.prototype.addChild = function(selector, view, append, render) {
  append = append || false;
  render = render === undefined ? true : render;

  if (!this._childViews) {
    this._childViews = [];
  }

  this._childViews.push({
    view: view,
    selector: selector,
    append: append,
    render: render
  });

  return this;
}

Backbone.View.prototype.removeChild = function(viewToRemove) {
  var index = findIndex(this._childViews, function(view) {
    return viewToRemove === view.view;
  });
  if(index > -1) {
    delete this._childViews[index];
  }
  this._childViews = compact(this._childViews);
}

/**
 * Walk through the assigned children and render them to their selectors
 * @param {jQuery} [parent] The element to search
 * @return {Backbone.View}
 */
Backbone.View.prototype.renderChildren = function(parent) {
  parent = parent ? $(parent) : this.$el;

  // Since it's not connected to the page it's fast to mess with
  if (this._childViews && this._childViews.length > 0) {
    this._childViews.forEach(
      function(row, index) {
        if(!row) return;
        if (row.selector) {
          var ele =
              typeof row.selector === 'string'
                ? parent.find(row.selector)
                : $(row.selector),
            el;

          try {
            el = row.render ? row.view.render().el : row.view.el;
          }catch(e){
            console.error('Failed to render ', row.selector);
            throw e;
          }

          if (index > 0 && row.append) {
            ele.append(el);
          } else {
            ele.html(el);
          }
        } else {
          parent.append(el);
        }
      }.bind(this)
    );
  }

  return this;
}

/**
 * Should just return the serlized data that is returned to the default render
 * @returns {object}
 */
Backbone.View.prototype.serialize = function() {
  return {};
}

/**
 * Default view method just renders its self.
 * Every view should either have its template defined, or a templatePath array with
 * @returns {Backbone.View}
 */
Backbone.View.prototype.render = function() {
  var ele;

  if(this.template) {
    // Make a floating dom element
    ele = $(this.template(this.serialize()));

    this.renderChildren(ele);

    // All at once add it to the dom
    this.$el.html(ele);

  } else {
    this.renderChildren();
  }

  if(this.layout) {
    var layout = (typeof this.layout === 'function' ) ? (new this.layout()) : this.layout;
    layout.render();
    layout.$('#app').append(this.el);
    const appendEle = (layout.env === 'site_typing') ? '.layout-app' : 'body'
    $(appendEle).append(layout.el)
  }

  // Have to call after elements are stuck to the dom
  this.initializeValidation();

  if(typeof CodeMirror === 'function') {
    window.setTimeout(() => this.setupIDE,0);
  }

  return this;
}

/**
 * If the necessary attributes are set (model and formSelector) then set up validation. Submits on .submit button
 * @protected
 * @returns {*}
 */
Backbone.View.prototype.initializeValidation = function() {
  if (this.formSelector && this.model) {
    this.validator = new FormValidator({
      form: this.$(this.formSelector),
      button: this.$('.js-submit,.submit'),
      buttonClick: this.buttonClick.bind(this),
      model: this.model,
      dataParser: this.dataParser ? this.dataParser.bind(this) : null, // data parser can be sent to optionally parse form data before hitting the server
      fileUpload: this.fileUpload // if set to a DOM form element, this will override backbone's sync and do an xhr upload
    });
    this.listenTo(this.validator, 'done', this.submitCallback);
    this.listenTo(this.validator, 'error', this.errorCallback);
    this.listenTo(this.validator, 'submitting', this.submittingCallback);
  }// else {
  //this.delegate('click', '.js-submit,.submit', this.submitCallback.bind(this));
  //}

  return this;
}

/**
 * Override this.  Called when a view with a form begins submitting
 */
Backbone.View.prototype.submittingCallback = function(data) {
  if(this.noticeStateMessages) {
    if(this.notice) {
      this.notice.close()
    }
    this.notice = this.getNewNoticeWithState({ noticeStateMessages: this.noticeStateMessages });
    this.notice.show(data)
  }
}

/**
 * Override this.  Called when a view with a from completes submit successfully
 */
Backbone.View.prototype.submitCallback = function(data) {
  if (this.successNotice) {
    var text =
      typeof this.successNotice == 'function'
        ? this.successNotice(data)
        : this.successNotice;
    this.getNewNotice({ text: text }).show();
  } else if(this.notice) {
    this.notice.showSuccess(data);
  }
}

Backbone.View.prototype.buttonClick = function() {
  this.validator.form.submit();
  return false;
}

/**
 * Override this to not show ugly alerts and handle the error gracefully
 */
Backbone.View.prototype.errorCallback = function(response) {
  // We need to ALWAYS remove the notice if it is open
  if(this.notice) {
    this.notice.showError(response?.message || response?.error)
  } else if (response) {
    if (typeof response == 'object') {
      if (response.message || response.error) {
        new this.getNewNotice({
          text: 'shared.view.error_callback_notice_text'.t({ error: response.message || response.error }),
          error: true
        }).show();
      }
    } else {
      window.alert(
        'shared.view.error_callback_invalid_response'.t({
          error: JSON.stringify(response)
        })
      );
    }
  } else {
    window.alert('shared.view.error_callback_error_communicating'.t());
  }
}

/**
 * Convenience method to reset forms
 */
Backbone.View.prototype.resetForm = function() {
  if (this.formSelector) {
    this.$(this.formSelector)[0].reset();
    this.model.clear();
    this.$('input[type=text]:eq(0)').focus();
  }
}

Backbone.View.prototype.togglePasswordDisplay = function(e) {
  var target = $(e.currentTarget),
    ele = target.parent().find('input');

  if(ele.attr('type') === 'text') {
    ele.attr('type', 'password');
    target.html('shared.password.show_button'.t());
  } else {
    ele.attr('type', 'text');
    target.html('shared.password.hide_button'.t());
  }
  ele.focus();

  return false;
}

Backbone.View.prototype.ides = null

Backbone.View.prototype.setupIDE = function() {
  var textarea = $('textarea.js-ide').toArray();
  if (textarea.length) {
    this.ides = [];
    textarea.forEach(
      function(t) {
        t.originalValue = t.value;
        $(textarea).removeClass('js-ide').addClass('js-has-ide');
        var ide = CodeMirror.fromTextArea(t, {
          lineNumbers: true,
          mode: $(t).data('ide') || 'htmlmixed',
          autoCloseBrackets: true,
          autoCloseTags: true,
          matchBrackets: true,
          lineWrapping: true,
          matchTags: true,
          indentWithTabs: true,
          indentUnit: 4,
          tabSize: 4,
          showTrailingSpace: true,
          lint: true,
          extraKeys: {
            'Ctrl-Q': function(cm) {
              cm.foldCode(cm.getCursor());
            }
          },
          foldCode: true,
          foldGutter: true,
          gutters: [
            'CodeMirror-linenumbers',
            'CodeMirror-foldgutter',
            'CodeMirror-lint-markers'
          ]
        });
        ide.on('change', function(cm) {
          t.value = cm.getValue();
        });
        ide.on('blur', function(cm) {
          if(t.originalValue !== cm.getValue()) {
            $(cm.getWrapperElement()).addClass('content-changed');
          } else {
            $(cm.getWrapperElement()).removeClass('content-changed');
          }
        });
        this.ides.push(ide);
      }.bind(this)
    );
  }
}

Backbone.View.prototype.destroyIDE = function() {
  if (this.ides) {
    this.ides.forEach(function(ide) {
      ide.toTextArea();
    });
    this.ides = [];
  }
}

Backbone.View.prototype.getNewNotice = function(options) {
  return new Notice(options);
}

Backbone.View.prototype.getNewNoticeWithState = function(options) {
  return new Notice(options);
}
