import $ from 'jquery'
import Tooltip from 'tooltip.js'
import { each } from 'lodash-es'

/**
 * Note that any element with class 'tooltip-trigger' will show this
 */
export default {
  cachedTooltips: {},

  init: function() {
    // init can be run any number of times and will clean up all the cached tips
    each(this.cachedTooltips, (tip) => tip.dispose())
    this.cachedTooltips = {};

    // it's written this way so we don't have to create all of the tooltips.. we lazy-create them as they hover
    $('body').off('mouseover.tooltip', '.has-tooltip,[data-tooltip]')
    .on('mouseover.tooltip','.has-tooltip,[data-tooltip]',(e) => this.show(e.currentTarget));
  },

  show: function(target, options) {
    options = options || {};

    var id = target.id,
      forced = !!options.text,
      text = options.text,
      classes = '',
      classList = $(target).find('.tooltip-html').attr('class');

    if (classList && classList !== 'tooltip-html') {
      classes = classList.replace('tooltip-html', '');
    }

    if($(target).data('placement')) {
      options.placement = $(target).data('placement');
    }

    if($(target).data('collapse')) {
      options.collapse = $(target).data('collapse');
    }

    if(!id) {
      id = 'id' + Math.random();
      target.id = id;
    }

    // Only show the tooltip if there is no active Tour being shown
    if($(target).attr('data-tour') && $('#' + $(target).attr('aria-describedby')).length > 0) {
      return false;
    }

    var template;
    if (options.start) {
      template = '<div class="tooltip tooltip--start" role="tooltip"><div class="tooltip-arrow"></div><h3 class="tooltip-inner mbf"></h3></div>';
    } else if ($(target).attr('data-modal')) {
      template = '<div class="tooltip tooltip--modal" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';
    } else {
      template =
        `<div class="tooltip ${classes} ${(options.collapse) ? options.collapse : null}" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-hoverGap"></div><div class="tooltip-inner"></div></div>`;
    }
    if(!this.cachedTooltips[id]) {
      if(!text) {
        text = $(target).data('tooltip');
      }
      if(!text) {
        text = $(target).children('.tooltip-html').html();
      }
      var tip = new Tooltip(target, {
        container: 'body',
        html: true,
        offset: options.offset || '0, 15',
        placement: (options.placement) ? options.placement : 'top',
        preventOverflow: { enabled: true },
        template: template,
        title: text,
        trigger: (options.trigger) ? options.trigger : 'hover',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              boundariesElement: 'viewport'
            }
          }
        }
      });
      if(options.force) {
        tip.show();
      }
      this.cachedTooltips[id] = tip;

      return tip;
    }
  },

  /**
   * Force remove a tooltip
   * @param window.Tooltip
   */
  hide: function(tip) {
    try {
      tip.hide()
    } catch(e) {}
  },

  hideAll: function() {
    each(this.cachedTooltips, (tip) => {
      this.hide(tip)
    });
  }
}
