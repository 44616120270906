import FormModel from '@shared/form_model'

export default FormModel.extend({
  idAttribute: 'order_id',

  url: '/apiv1/site/order',

  validationUrl: '/apiv1/site/order',

  defaults: {
    total_annual: 0,
    quantity_annual: 0,
    quantity_add: 0
  },

  validationRules: {
    orgType: {
      required: function(element) {
        return element.form.current_step.value === '1';
      }
    },
    quantity_annual: {
      required: function(element) {
        return element.form.current_step.value === '1';
      },
      number: true,
      range: [1, 99999]
    },
    months_annual: {
      required: function(element) {
        return element.form.current_step.value === '1';
      },
      number: true,
      range: [1, 99999]
    },
    email: {
      email: true,
      required: function(element) {
        return element.form.current_step.value === '1';
      }
    },
    first_name: {
      required: function(element) {
        return element.form.current_step.value === '2';
      }
    },
    last_name: {
      required: function(element) {
        return element.form.current_step.value === '2';
      }
    },
    role: {
      required: function(element) {
        return element.form.current_step.value === '2';
      }
    },
    country: {
      required: function(element) {
        return element.form.current_step.value === '2';
      }
    },
    state: {
      required: function(element) {
        return element.form.current_step.value === '2' && element.form.country.value === 'US';
      }
    },
    province: {
      required: function(element) {
        return element.form.current_step.value === '2' && element.form.country.value === 'CA';
      }
    },
    school_name: {
      required: function (element) {
        return (element.form.current_step.value === '2' && element.form.country.value === 'US' && element.form.manual_name.value && (element.form.orgType.value === 'school' || element.form.orgType.value === 'teacher')) ||
          (element.form.current_step.value === '2' && element.form.country.value !== 'US' && (element.form.orgType.value === 'school' || element.form.orgType.value === 'teacher'))
      }
    },
    district_name: {
      required: function (element) {
        return (element.form.current_step.value === '2' && element.form.country.value === 'US' && element.form.manual_name.value && element.form.orgType.value === 'district') ||
          (element.form.current_step.value === '2' && element.form.country.value !== 'US' && element.form.orgType.value === 'district')
      }
    },
    data_school_id: {
      required: function (element) {
        return element.form.current_step.value === '2' && element.form.country.value === 'US' && !element.form.manual_name.value && (element.form.orgType.value === 'school' || element.form.orgType.value === 'teacher')
      }
    },
    data_district_id: {
      required: function (element) {
        return element.form.current_step.value === '2' && element.form.country.value === 'US' && !element.form.manual_name.value && element.form.orgType.value === 'district'
      }
    },
  },

  /**
   * Calculate the total price of an order
   * @param term - length of order in years
   * @param quantity - number of seats
   * @param currentSeats - number of seats in the current license
   * @returns {Number} dollar value as a float xx.xx
   */
  calculateTotalPrice: function(term = 1, quantity, currentSeats) {
    var unitPrice = this.calculateUnitPrice(quantity, currentSeats),
      totalPrice = Number(quantity * unitPrice * term * 100 / 100),
      discount = currentSeats > 0 ? 0 : totalPrice * this.getDiscount(term);

    return (totalPrice - discount);
  },

  getDiscount: function(term) {
    return FTWGLOBALS('discounts')[Math.min(2, Math.max(0, (term || 1) - 1))];
  },

  calculateUnitPrice: function(quantity, currentSeats) {
    var pricing = FTWGLOBALS('pricing');
    currentSeats = currentSeats || 0;

    // When buying additional licenses, factor in the previous purchase to give customers a better price ;)
    quantity = Number(quantity) + Number(currentSeats);

    var price = pricing.filter(function(row) { return quantity >= row[0] && quantity <= row[1]; });

    return price.length > 0 ? price[0][2] : 0;
  }
})
