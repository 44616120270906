import Backbone from 'backbone'
import $ from 'jquery'
import Velocity from 'velocity-animate'
import { shared_notice } from '@templates/shared'

export default Backbone.View.extend({
  template: shared_notice,

  events: {
    'click .js-close': 'close',
    'mouseover': 'hover'
  },

  text: 'no notice text set',
  error: false,
  timeout: null,

  serialize: function() {
    return {
      error: this.error,
      text: this.text
    }
  },

  show: function() {
    this.render();

    $('body').append(this.$el);
    Velocity(this.$('.js-notice'), 'fadeIn', 500);
    this.timeout = window.setTimeout( () => this.close(), 5000);
    return this;
  },

  close: function() {
    window.clearTimeout(this.timeout);
    Velocity(this.$('.js-notice'), 'fadeOut', () => this.$('.js-notice').remove());
    return false;
  },

  hover: function() {
    // clearTimeout(this.timeout);
  }
});
