import Backbone from 'backbone'
import { each, clone } from 'lodash-es'

Backbone.Collection.prototype.initialize = function() {
  this.listenTo(this, 'change', function() {
    this._hasChanged = true;
  });
};

/**
   * See if the model has ever been reset.
   * This is useful to show spinners if = false
   * @returns {boolean}
   */
Backbone.Collection.prototype.hasChanged = function() {
  return this._hasChanged || false;
};

/**
   * Overwrite the parse method for our type of API
   * @param data
   * @returns array
   */
//Backbone.Model.prototype.parse = function(data, req) {
//    return data;
//};

/**
   * Set the persistent local storage backend
   * @param {Function} backend The backend object
   * @param {String} resolveDataFrom backend|model Where should data initially come from.  If 'backend', then it will overwrite anything currently in the model.  And vice versa for 'model'
   * @returns {Backbone.Model}
   */
Backbone.Model.prototype.setBackend = function(backend, resolveDataFrom) {
  if (this.backend) {
    this.off('change', this.backend.change);
  }

  backend.setModel(this);
  this.on('change', backend.change, backend);
  if (resolveDataFrom == 'backend') {
    this.set(backend.getData());
  } else if (resolveDataFrom == 'model') {
    backend.setData(this.toJSON());
  }

  this.backend = backend;

  return this;
};

/**
   * Increment a value
   * @param {*} key|object.  Can be in the form key,inc or {key:inc,key2:inc2}
   * @returns {*} Whatever Backbone.Model.prototype.set returns
   */
Backbone.Model.prototype.inc = function() {
  var oldVal;
  var update = {};

  if (typeof arguments[0] == 'object') {
    oldVal = null;
    each(arguments[0], (val, key) => {
      oldVal = this.get(key) || 0;
      update[key] = oldVal + val;
    });
    return this.set(update, arguments[1]);
  } else if (typeof arguments[0] == 'string' && arguments[1]) {
    (oldVal = this.get(arguments[0]) || 0),
    (update[arguments[0]] = oldVal + arguments[1]);

    return this.set(update, arguments[3]);
  }
}

/**
 * Backbone will pass a reference (pointer) for arrays and objects. That's NO GOOD.
 * @param attr
 * @returns {*}
 */
Backbone.Model.prototype.getClone = function(attr) {
  return clone(this.attributes[attr])
}
