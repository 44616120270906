import $ from 'jquery'
import moment from 'moment'
import Velocity from 'velocity-animate'
import flickity from 'flickity'
import svg4everybody from 'svg4everybody'
import '@shared/globals';
import BugsnagConfig from '@shared/bugsnag'
import Router from './router'
import Analytics from '@shared/analytics'
import exportLocation from '@shared/geolocation'
import LazyLoad from 'lazyload'
import '@shared/link_tracker'
import { throttle } from 'lodash-es'
import Tooltip from './global/tooltip.js'
import { rot47, mapLanguageToLibrary, Cookies } from '@shared/helpers'
import '../styles/app.css'

const App = {

  initialize: function () {
    if(!this.hostCheck()) {
      return;
    }

    moment.locale(mapLanguageToLibrary('moment', FTWGLOBALS('language')));

    $.ajaxSetup({
      beforeSend: function (xhr) {
        xhr.setRequestHeader('x-language', FTWGLOBALS('language'));
      }.bind(this)
    });

    this.bugsnag();

    this.initLazyload();

    this.setupFixedNav();

    this.initializeHeader();

    this.analytics();

    this.setLocation();

    this.showBenefits();

    Tooltip.init()


    // If the user tabs a dropdown into focus, open it and focus on the first item when enter is clicked
    $('.js-dropdown').keydown(function(e) {
      if(e.which === 13  && (!$(e.target).hasClass('js-dropdown-item'))) {
        e.preventDefault;
        $(this).addClass('is-active');
        $('.js-dropdown.is-active .js-dropdown-item').eq(0).focus();
      }
    });

    // If the user clicks anywhere on the page while an accessibility dropdown is open, close it
    $('.js-dropdown').focus(function() {
      const dropdown = $(this);
      $('body').on('click.dropdown', function(e) {
        if (!$(e.target).closest('.js-dropdown').length) {
          dropdown.removeClass('is-active');
          $('body').off('click.dropdown');
        }
      });
    });

    // Handle various key inputs while the dropdown items are in focus
    $('.js-dropdown-item').keydown(function(e) {
      var dropdown = $(this).closest('.js-dropdown'),
        dropdownId  = dropdown.attr('data-dropdown'),
        dropdownItems = $(`.js-dropdown[data-dropdown=${dropdownId}] .js-dropdown-item`),
        index = dropdownItems.index(this);
      e.preventDefault;
      if(e.which === 27) {
        dropdown.removeClass('is-active');
        window.setTimeout(() => dropdown.focus(), 10);
      }
      if(e.shiftKey && e.which === 9) {
        if (index ===  0) {
          window.setTimeout(() => dropdownItems.eq(dropdownItems.length - 1).focus(), 10);
        } else {
          window.setTimeout(() => dropdownItems.eq(index - 1).focus(), 10);
        }
      } else if(e.which === 9) {
        if (index < dropdownItems.length - 1) {
          window.setTimeout(() => dropdownItems.eq(index + 1).focus(), 10);
        } else {
          window.setTimeout(() => dropdownItems.eq(0).focus(), 10);
        }
      }
    });

    /*
    *
    * This script helps to change the language of the website without changing the current page.
    * It does this by modifying the URLs based on the language selected by the user.
    *
    */
    $('.js-language-dropdown-item').on('click', function (e) {
      e.preventDefault();
      var pathArray = window.location.pathname.split('/').filter(Boolean);

      var languageId = $(this).data('id');
      localStorage.setItem('tc:language', languageId);
      var newUrl = '/' + languageId + '/' + (Object.keys(typeof FTWGLOBALS('languages') === 'object' ? FTWGLOBALS('languages') : {}).includes(pathArray[0]) ? pathArray.slice(1) : pathArray).join('/');
      window.location.href = newUrl;
  })

    $('.js-trigger-mobile-menu').on('click', () => {
      const mobileMenu = $('.js-mobile-menu');
      const mobileMenuOverlay = $('.js-mobile-menu-overlay');

      if(!mobileMenu.hasClass('is-active')) {
        Velocity(mobileMenu, {'opacity': [1,0], translateX: [0,"100%"]}, {display: 'flex', duration: 250, complete: () => { mobileMenu.addClass('is-active') }});
        Velocity(mobileMenuOverlay, 'fadeIn');
      } else {
        Velocity(mobileMenu, {translateX: "100%"}, {display: 'none', duration: 125, complete: () => { mobileMenu.removeClass('is-active') }});
        Velocity(mobileMenuOverlay, 'fadeOut', 125);
      }
    })

    $('.js-trigger-signup-prompt').on('click', () => {
      const signupPrompt = $('.js-signup-prompt');

      if(!signupPrompt.hasClass('is-active')) {
        Velocity(signupPrompt, {translateY: [0,"100%"]}, {display: 'flex', duration: 250, complete: () => { signupPrompt.addClass('is-active') }});
      } else {
        Velocity(signupPrompt, {translateY: "100%"}, {display: 'none', duration: 125, complete: () => { signupPrompt.removeClass('is-active') }});
      }
    })

    if(window.location.pathname === '/schools_districts') {
      const isScrolledIntoView = (el) => {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        // Only completely visible elements return true:
        // var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight; // <<< TWEAK THIS VALUE TO CHANGE WHEN A SECTION IS CONSIDERED IN VIEW
        // Partially visible elements return true:
        return elemTop < window.innerHeight && elemBottom >= 250;
      }
      const SECTIONS = [
        {
          id: "one",
          link: document.querySelector("#link-block-one"),
          block: document.querySelector("#block-one"),
        },
        {
          id: "two",
          link: document.querySelector("#link-block-two"),
          block: document.querySelector("#block-two"),
        },
        {
          id: "three",
          link: document.querySelector("#link-block-three"),
          block: document.querySelector("#block-three"),
        },
        {
          id: "four",
          link: document.querySelector("#link-block-four"),
          block: document.querySelector("#block-four"),
        },
      ]
      window.addEventListener(
        "scroll",
        () => {
          let foundSectionInView = false;
          SECTIONS.forEach(({
            id,
            link: linkEl,
            block: blockEl,
          }) => {
            const scrolledIntoView = isScrolledIntoView(blockEl);
            if (scrolledIntoView && foundSectionInView === false) {
              linkEl.style.color = "#3082CF";
              linkEl.firstElementChild.style.borderBottomColor = "#3082CF"
              foundSectionInView = true;
            } else {
              linkEl.style.color = "#4E535A";
              linkEl.firstElementChild.style.borderBottomColor = "#EAEAEC"
            }
          });
        },
        false)
    }

    // Start the router
    Router.initialize();
  },

  initializeHeader: function() {
    const openModalEle = $('.js-open-modal'),
      closeModalEle = $('.js-close-modal'),
      closeModalFn = (e) => {
        const modalOverlay = $('.js-modal-overlay'),
          modalContent = $('.js-modal-content.is-active')

        modalContent.removeClass('is-active')
        Velocity(
          modalOverlay,
          {opacity: 0},
          {
            display: 'none',
            duration: 200,
            easing: 'easeOut',
            complete: () => {
              modalContent.fastHide()
            }
          }
        )
      }

    openModalEle.on('click', (e) => {
      const modalOverlay = $('.js-modal-overlay'),
        ele = $(e.currentTarget),
        modalActionType = ele.data('id'),
        modalContent = $(`.js-modal-content[data-id=${modalActionType}]`);

      modalContent.addClass('is-active')
      modalContent.fastShow()

      Velocity(
        modalOverlay,
        {opacity: 1},
        {
          display: 'flex',
          duration: 400,
          easing: 'easeIn',
        }
      )
    })

    closeModalEle.on('click', closeModalFn)

    $(document).mouseup((e) => {
      if($('.js-modal-overlay').has(e.target).length === 0) {
        closeModalFn(e)
      }
    })
  },

  /**
   * Set up the fixed nav when they scroll down
   */
  setupFixedNav: function () {
    const nav = $('.js-nav');
    const secondaryNav = $('.js-nav-secondary');
    const announcement = $('.js-announcement');
    const offset = 45;

    const navFn = function () {
      const secondaryNavHeight = secondaryNav.outerHeight() || 0,
        announcementHeight = announcement.outerHeight() || 0;

      let isFixed = window.pageYOffset > secondaryNavHeight + announcementHeight - offset;

      if (isFixed) {
        nav.addClass('is-fixed');
      } else {
        nav.removeClass('is-fixed');
      }
    };
    navFn();
    $(window).scroll(() => {
      if(!$('.js-mobile-menu').hasClass('is-active')) {
        navFn();
      }
    });
  },

  setLocation: function () {
    exportLocation()
      .then((response = {}) => {
        if(response.state === 'TX') {
          $('.js-site-texas-section').fastShow()
          $('#homepage-bg').removeClass('-skew-y-8');
        }
      })
      .catch();
  },

  showBenefits: function() {
    // Benefit Names
    const benefits = $('.js-benefit'),
      media = $('.js-benefits-presentation').attr('data-id'),
      presentationEle = $(`.js-benefits-${media}`);

    // Select benefit on mouseover
    benefits.on("mouseover", (e) => {
      var ele = $(e.currentTarget);
      if(ele.hasClass('is-active')) { return false; }

      benefits.removeClass('is-active');
      ele.addClass('is-active');
      presentationEle.attr('src', `/dist/site_typing/images/benefits/${ele.attr('data-id')}${(media === 'video') ? '.mp4' : '.png'}`);
    });
  },

  closeLangDropdown: function(lang) {
    $('.js-language-dropdown').removeClass('is-hover');
    $('.js-language-dropdown .js-language-items').attr('style', '');
    $('.js-language-dropdown .js-language-item[data-id=' + lang + ']').removeClass('is-active');
  },

  analytics: function() {
    try {
      ga('send', 'pageview');
      Analytics.setActivityTimeout();
    } catch(e) {
      console.log(e)
    }
  },

  bugsnag: () => {
    BugsnagConfig.init()
  },

  initLazyload: () => {
    lazyload();
  },

  hostCheck: function() {
    // Only allow whitelabel or typing.com host names
    if(FTWGLOBALS('app_hostname') !== window.location.hostname) {
      window.location.href = 'https://www.typing.com/student/login';
      return false;
    }

    return true;
  },
}

$(() => {
  App.initialize();
  svg4everybody();
});
