const getEvalStr = (tmplData) => { if(Object.keys(tmplData).length > 0) { return 'var ' + Object.keys(tmplData).map((key) => key + ' = tmplData["' + key + '"]').join(',') + ';' } else { return '' } };export const curriculum_grade = function(tmplData) {  eval(getEvalStr(tmplData));
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="max-w-screen-xl mx-auto flex flex-col space-y-3">\n  ';
units.forEach((unit, index) => {;
__p += '\n    <div class="' +
((__t = ((index === 0 ? 'is-active' : ''))) == null ? '' : __t) +
' transition-colors duration-500 rounded cursor-pointer group js-unit-accordion" style="background: rgba(234, 248, 255, 1);" data-id="' +
((__t = (index)) == null ? '' : __t) +
'">\n      <div class="flex items-center justify-between text-gray-800 px-6 py-4">\n        <div class="flex flex-col space-y-1">\n          <h2 class="text-2xl font-semibold">' +
((__t = (unit.name)) == null ? '' : __t) +
'</h2>\n          <p class="text-base">' +
((__t = (unit.description)) == null ? '' : __t) +
'</p>\n        </div>\n        <svg class="w-4 h-4 ml-4 transition-transform transform stroke-current min-w-6 stroke-3 is-active:rotate-180">\n          <use xlink:href="/dist/teacher/images/icons-compiled.svg#caret"></use>\n        </svg>\n      </div>\n      <div class="hidden text-base is-active:block js-accordion-text bg-white px-6 py-4" style="border: 3px solid rgba(234, 248, 255, 1);">\n        <ul class="flex flex-col space-y-2">\n          ';
lessonCount = 0;
__p += '\n          ';
lessons.filter(lesson => lesson.unit_id === unit.unit_id).forEach(lesson => {;
__p += '\n            ';
if(lesson.type === 'grouping') {;
__p += '\n              <li class="text-lg font-bold">' +
((__t = (lesson.name)) == null ? '' : __t) +
'</li>\n            ';
} else {;
__p += '\n              ';
lessonCount++;
__p += '\n              <li class="flex items-center">\n                <div class="flex items-center justify-center w-8 h-8 mr-2 text-blue-600 bg-blue-100 rounded-full text-lg">\n                  ' +
((__t = (lessonCount)) == null ? '' : __t) +
'\n                </div>\n                ' +
((__t = (lesson.name)) == null ? '' : __t) +
'\n              </li>\n            ';
};
__p += '\n          ';
});
__p += '\n        </ul>\n      </div>\n    </div>\n  ';
});
__p += '\n</div>\n';
return __p
};export const price_quote_order_details = function(tmplData) {  eval(getEvalStr(tmplData));
var __t, __p = '';
__p += '<div class="flex flex-col">\n  <div class="flex">\n    <div class="mr-4">\n      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">\n        <rect width="46" height="46" rx="23" fill="#84DC91" />\n        <path d="M12 25.75C12.9882 26.75 16.5294 30.3333 18.1765 32C23.1176 27.1389 33 17.3333 33 17" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />\n      </svg>\n    </div>\n    <div class="">\n      <h2 class="text-2xl font-semibold text-gray-800">' +
((__t = ('price_quote.full_quote_complete'.t())) == null ? '' : __t) +
'</h2>\n      <p class="text-gray-600 mb-4 text-sm">' +
((__t = ('price_quote.quote_for'.t({org_name: order.school_name || order.district_name, contact_name: order.first_name + ' ' + order.last_name}))) == null ? '' : __t) +
'</p>\n    </div>\n  </div>\n  <div class="w-full mb-4">\n    <div class="flex justify-between items-center mb-4 p-3 border border-blue-200 rounded bg-blue-50">\n      <span class="text-blue-600">' +
((__t = ('price_quote.reference_number_title'.t())) == null ? '' : __t) +
'</span>\n      <span class="text-blue-600">' +
((__t = (order.prefix + '-' + order.order_id)) == null ? '' : __t) +
'</span>\n    </div>\n    <div class="flex justify-between items-center mb-3">\n      <span class="text-gray-600 text-sm">' +
((__t = ('price_quote.billing_information_title'.t())) == null ? '' : __t) +
'</span>\n      <span class="text-gray-800">' +
((__t = (order.first_name)) == null ? '' : __t) +
' ' +
((__t = (order.last_name)) == null ? '' : __t) +
'</span>\n    </div>\n    <div class="flex justify-between items-center mb-3">\n      <span class="text-gray-600 text-sm">' +
((__t = ('teks.pricing.seats_label'.t())) == null ? '' : __t) +
'</span>\n      <span class="text-gray-800 font-bold">' +
((__t = ('price_quote.seats'.t({smart_count: order.quantity_annual}))) == null ? '' : __t) +
'</span>\n    </div>\n    <div class="flex justify-between items-center">\n      <span class="text-gray-600 text-sm">' +
((__t = ('teks.pricing.term_label'.t())) == null ? '' : __t) +
'</span>\n      <span class="text-gray-800 font-bold">' +
((__t = ('price_quote.term'.t({smart_count: Math.round(order.months_annual/12)}))) == null ? '' : __t) +
'</span>\n    </div>\n  </div>\n  <div class="w-full border-t border-gray-200 mb-6"></div>\n  <div class="flex justify-between mb-4 items-baseline">\n    <div class="text-lg font-semibold">\n      ' +
((__t = ('price_quote.total'.t())) == null ? '' : __t) +
'\n    </div>\n    <div class="text-4xl font-semibold">$' +
((__t = (order.total_annual)) == null ? '' : __t) +
'</div>\n  </div>\n  <a href="/apiv1/site/order/pdf/' +
((__t = ((order.prefix + '-' + order.order_id))) == null ? '' : __t) +
'?language=' +
((__t = (language)) == null ? '' : __t) +
'" target="_blank" class="w-full btn btn-blue mb-6 flex justify-center items-center  text-2xl ">\n    <img data-src="/dist/site_typing/images/premium/download.svg"\n        src="/dist/site_typing/images/premium/download.svg" alt="" class="block w-6 lazyload mr-2">\n    ' +
((__t = ('price_quote.download_pdf_button'.t())) == null ? '' : __t) +
'\n  </a>\n  <div class="relative w-full flex justify-between items-center mb-6">\n    <p class="text-gray-600 text-sm">' +
((__t = ('price_quote.copy_link'.t())) == null ? '' : __t) +
'</p>\n    <button class="js-copy text-blue-600 text-sm underline flex items-center">\n      typing.com/price-quote#' +
((__t = (order.prefix)) == null ? '' : __t) +
'-' +
((__t = (order.order_id)) == null ? '' : __t) +
'\n      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-2">\n        <path\n          d="M9 2C7.89543 2 7 2.89543 7 4V12C7 13.1046 7.89543 14 9 14H15C16.1046 14 17 13.1046 17 12V6.41421C17 5.88378 16.7893 5.37507 16.4142 5L14 2.58579C13.6249 2.21071 13.1162 2 12.5858 2H9Z"\n          fill="#48A1DF" />\n        <path d="M3 8C3 6.89543 3.89543 6 5 6V16H13C13 17.1046 12.1046 18 11 18H5C3.89543 18 3 17.1046 3 16V8Z"\n          fill="#48A1DF" />\n      </svg>\n    </button>\n    <div class="absolute rounded p-2 border border-yellow-400 bg-gradient-to-b from-yellow-100 to-yellow-200 text-gray-900 js-copy-notification left-1/2 transform -translate-x-1/2" style="display:none; top: -3rem;">\n      ' +
((__t = ('add.self_alert_url_copied'.t())) == null ? '' : __t) +
'\n    </div>\n  </div>\n  <div class="w-full border-t border-gray-200 mb-4"></div>\n  <p class=" text-xs mb-4">' +
((__t = ('price_quote.important_text'.t())) == null ? '' : __t) +
' <a href="' +
((__t = (teachersAppUrl + '/signup')) == null ? '' : __t) +
'" class="text-blue-600 underline">teachers.typing.com/signup</a></p>\n  <div class="flex justify-center"> <a href="/price-quote" class="text-blue-600 text-sm underline">' +
((__t = ('price_quote.get_another_quote'.t())) == null ? '' : __t) +
'</a></div>\n</div>\n';
return __p
};