import Backbone from 'backbone'
import $ from 'jquery'
import { Cookies, googleLogin, cleverLogin } from '@shared/helpers'

export default Backbone.View.extend({
  el: 'body',

  events: {
    'click .js-google-signup': 'ssoSignup',
    'click .js-clever-signup': 'ssoSignup',
    'click .js-classlink-signup': 'classlinkSignup',
    'click .js-submit': 'handleSubmit',
    'keypress': 'handleEnter'
  },

  ssoSignup: function(e) {
    var loginType = $(e.currentTarget).data('id');
    this.$('input[name=login_type]').val(loginType);

    Cookies.set('sso', 'teacher', {path:'/'});
    Cookies.set('teacher_id', '', {path:'/'});
    Cookies.set('invite_email', '', {path:'/'});
    Cookies.set('lang', FTWGLOBALS('language'), {path: '/', expires: 30});

    if(loginType === 'google') {
      googleLogin('teacher')
    } else if (loginType === 'clever') {
      cleverLogin('teacher')
    }
    return false;
  },

  classlinkSignup: function() {
    window.location.href = 'https://launchpad.classlink.com/';

    return false;
  },

  handleSubmit: function() {
    var email = $('input[name=email]').val(),
      fullName = $('input[name=full_name]').val();

    $('.js-submit').addClass('btn--loading');

    window.location.href = __url('/teacher/signup#' + email + '|' + fullName);
    return false;
  },

  handleEnter: function(e) {
    if (e.which === 13) {
      this.handleSubmit();
      return false;
    }
  }

})
