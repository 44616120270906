import Backbone from 'backbone'
import $ from 'jquery'
import Notice from './notice'
import Velocity from 'velocity-animate'
import VelocityUI from 'velocity-animate/velocity.ui'
import { extend, each, isObject, compact, uniq, isArray, flatten, defaults } from 'lodash-es'

const JQUERY_VALIDATION_IGNORE = '.ignore';

$.validator.setDefaults({
  ignore: JQUERY_VALIDATION_IGNORE
});

/**
 * Binds Form Validation to a Model, and handle server side success/errors
 * Object created is also a jQuery.Deferred object
 *
 * @param {Object} options
 * @param {jQuery} options.form     The from that is being validated
 * @param {jQuery} options.button   The button to bind to for submit
 * @param {Backbone.Model} options.model    The model
 * @param {Number} options.submitDelay      A delay in milliseconds to submit the form to make it "feel" better to the user
 */
const FormValidator = function(options) {
  options = options || {};
  options.submitDelay = options.submitDelay || 300;
  extend(this, options);

  if (this.fileUpload) {
    this.model.fileUpload = this.form;
  }

  // Uses a promise to communicate
  extend(this, Backbone.Events);

  // add proper translation strings where they aren't explicitly set
  var rules = this.model.validationRules || {},
    messages = this.model.validationMessages || {};
  each(rules, (fieldRules, field) => {
    if(isObject(fieldRules)) {
      each(fieldRules, (def, rule) => {
        var fieldMessages = messages[field] || {};
        if(!fieldMessages[rule]) {
          fieldMessages[rule] = ('shared.validation.' + rule).t();
        }
        messages[field] = fieldMessages;
      });
    }
  });

  this.validatorOptions = {
    errorPlacement: this.errorPlacement.bind(this),
    submitHandler: this.submitHandler.bind(this),
    invalidHandler: this.errorHandler.bind(this),
    rules: rules,
    messages: messages,
    onfocusout: false,
    highlight: this.highlight,
    unhighlight: this.unhighlight,
    errorClass: 'is-error',
    errorElement: 'p',
  };

  // Initiate jQuery Validation
  this.validator = this.getValidator();

  this.form.find('input').keypress(
    function(e) {
      if (e.keyCode === 13) {
        // Prevent jquery validation from validating twice
        $(e.currentTarget).blur();
        this.form.submit();
        return false;
      }
    }.bind(this)
  );

  // Submit form on button click
  this.button.click(this.buttonClick.bind(this));
};

FormValidator.prototype.getValidator = function(options) {
  return this.form.validate({ ...this.validatorOptions, ...options });
};

/**
 * Enable/disable validation rules.  This can be useful to submit a form regardless of validation account (like saving a draft)
 * @param {boolean} validate    True for validation, false to disable
 */
FormValidator.prototype.toggleValidation = function(validate) {
  if (validate) {
    this.validator.settings.ignore = JQUERY_VALIDATION_IGNORE;
  } else {
    this.validator.settings.ignore = '*';
  }
};

/**
 * Called when the button the submit button is clicked
 * @returns {boolean}
 */
FormValidator.prototype.buttonClick = function() {
  this.form.submit();
  return false;
};

/**
 * Called by jQuery Validate when there's a form error
 */
FormValidator.prototype.errorHandler = function(event, validator) {
  var errorInputs = this.getErrorElements(validator).map(ele => {
    if($(ele[0]).hasClass('selectized')) {
      return $(ele[0]).next()
    }
    return ele
  })

  Velocity(errorInputs, 'ftw.miniShake', function() {
    errorInputs.forEach(function(ele) {
      ele.css({ transform: '' });
    });
  });
  this.trigger('invalid');
  return false;
};

FormValidator.prototype.getErrorElements = function(validator) {
  return this.validator.errorList.map(function(row) {
    if (
      $(row.element)
        .next()
        .hasClass('chosen-container') ||
      $(row.element)
      .hasClass('selectized')
    ) {
      return $(row.element).next();
    } else {
      return $(row.element);
    }
  });
};

/**
 * Called by jQuery validate to determine where to place error messages
 * @param {jQuery} error
 * @param {jQuery} element
 */
FormValidator.prototype.errorPlacement = function(error, element) {
  error.attr('role', 'alert');
  var fieldset = element.closest('.fieldset'),
  splitcell = fieldset.find('.split-cell:nth-child(2)');

  if (fieldset.length && splitcell.length) {
    $(error).addClass('error');
    splitcell.html(error);
  } else if(fieldset.length) {
    $(error).addClass('error');
    fieldset.append(error);
  } else {
    element
      .parent()
      .append(error)
      .addClass('error');
  }
};

FormValidator.prototype.highlight = function(element, errorClass) {
  if (
    $(element).next().hasClass('chosen-container') ||
    $(element).hasClass('selectized')
  ) {
    $(element)
      .next()
      .addClass('is-error');
  } else {
    $(element).addClass(errorClass);
  }
};

FormValidator.prototype.unhighlight = function(element, errorClass) {
  if (
    $(element).next().hasClass('chosen-container') ||
    $(element).hasClass('selectized')
  ) {
    $(element)
      .next()
      .removeClass(errorClass);
  } else {
    $(element).removeClass(errorClass);

    $(element)
      .parent()
      .removeClass('error');
  }
};

/**
 * Populates the model based on successful form submission; handles API success/error
 */
FormValidator.prototype.submitHandler = function() {
  // Prevent double submit
  if (this.submitting) {
    return;
  }

  this.disableButton();
  this.trigger('submitting');

  // Get the data
  var data = {};
  var oldData = this.model.toJSON();
  var formData = [];
  var fields = compact(uniq(this.form.find('input,textarea,select').map(function(index, ele){ return ele.name; })));
  fields.forEach(function(name){
    var $ele = this.form.find('[name="' + name + '"]'),
      type,
      value;

    if($ele.length === 1) {
      type = $ele.attr('type') || 'text';
      if(type === 'checkbox') {
        value = ($ele.prop('checked')) ? ($ele.val() || 1) : 0;
      } else if(type === 'radio') {
        if($ele.prop('checked')) {
          value = $ele.val();
        }
      } else {
        value = $ele.val();
      }
    } else {
      value = $ele.toArray().map(function(ele){
        var $ele = $(ele),
          value;
        type = $ele.attr('type') || 'text';

        if(type === 'checkbox') {
          value = ($ele.prop('checked')) ? ($ele.val() || 1) : undefined;
        } else if(type === 'radio') {
          if($ele.prop('checked')) {
            value = $ele.val();
          }
        } else {
          value = $ele.val();
        }
        return value;
      }).filter(function(val){
        return val !== undefined;
      });
    }

    if(value !== undefined) {
      formData.push({
        name: $ele.attr('name'),
        value: value
      });
    }
  }.bind(this));

  if (this.dataParser) {
    formData = this.dataParser(formData, this.form);
  }
  formData.forEach(function(field) {
    // if (field.value != oldData[field.name]) {
    if (data[field.name]) {
      if (isArray(data[field.name])) {
        data[field.name].push(field.value);
      } else {
        data[field.name] = [data[field.name]];
        data[field.name].push(field.value);
      }
    } else {
      data[field.name] = field.value;
    }
    // }
  });

  // Save the model, on a delay
  window.setTimeout(
    function() {
      this.save(data, { patch: true, silent: false, wait: true });
    }.bind(this),
    this.submitDelay || 0
  );
};

/**
 * Save the data to the server and handle success/errors
 * @param data
 * @param options
 */
FormValidator.prototype.save = function(data, options) {
  if (this.model.url || this.model.urlRoot) {
    this.model
      .save(data, options)
      .done(
        function(response) {
          this.enableButton();
          this.saveSuccess(response);
        }.bind(this)
      )
      .fail(
        function(response) {
          this.enableButton();
          var data = {};
          if (response.responseJSON) {
            data = response.responseJSON;
          } else {
            data = {
              error:
                response.responseText ||
                'shared.form_validator.unknown_error'.t()
            };
          }

          // if (data.error) {
          //   Backbone.View.prototype.getNewNotice({ error: true, text: data.error.t() }).show(this.button);
          // }

          this.saveError(data);
        }.bind(this)
      );
  } else {
    // if the model does not have a URL, just fake it.  means we want the form data but not to hit an API
    this.model.set(data);
    this.enableButton();
    this.saveSuccess(data);
  }
};

/**
 * On success, this resolves the promise
 * @param response
 */
FormValidator.prototype.saveSuccess = function(data) {
  this.trigger('done', data, this.model, this.form);
};

/**
 * Display and translate returned strings
 * @param data
 */
FormValidator.prototype.saveError = function(data) {
  var inputs = [];
  if(this.form && this.form.length) {
    // get all the form element names
    inputs = flatten(this.form.find('input,select,textarea').toArray().map(function(ele){ return $(ele).attr('name')}));
  }

    // translate any messages that map to inputs, or 'message' or 'error'
    data = each(data || {}, (val, key) => {
      if(key === 'message' || key === 'error' || inputs.indexOf(key) !== -1) {
        if(isArray(val)) {
          data[key] = val.map(function(msg){return msg.t(defaults({sub: data[key+'_sub']}, data));});
        } else {
          data[key] = val.t(defaults({sub: data[key+'_sub']}, data));
        }
      }
    });

    if (data) {
      if (typeof data == 'object') {
        try {
          var errors = data.message && data.errors ? data.errors : data;
          var errorsToShow = {};
          each(errors, (val, key) => {
            if($('form [name='+key+']').length) {
              errorsToShow[key] = val;
            }
          });
          this.form.validate().showErrors(errorsToShow);
          this.errorHandler();
          this.form.find('[name=' + Object.keys(errorsToShow)[0] + ']').focus();
        } catch (e) {
          console.error(e); // eslint-disable-line
        }
      }
    }

  this.trigger('error', data);
  //this.notify(response);
};

/**
 * Sets the button to disabled mode and disables the button being clicked again
 */
FormValidator.prototype.disableButton = function() {
  this.button.addClass('btn--loading');
  this.submitting = true;
};

/**
 * Brings the button back from pending mode and allows the form to submit again
 */
FormValidator.prototype.enableButton = function() {
  this.button.removeClass('btn--loading');
  this.submitting = false;
};

/**
 * Validates the form and shows errors
 * @return boolean true if the form is valid
 */
FormValidator.prototype.validate = function() {
  return this.validator.form();
};

export default FormValidator;
