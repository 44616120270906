import $ from 'jquery'
import selectize from '@selectize/selectize'
import Model from './models/upgrade'
import OrderDetailsView from './views/order_details'
import { Cookies } from '@shared/helpers';

export default Backbone.View.extend({
  el: 'body',
  discount: 0,

  events: {
    'click .js-quote-next-step': 'nextQuoteStep',
    'click .js-quote-reset-step': 'resetQuoteStep',
    'click .js-show-promo': 'showPromoInput',
    'click .js-apply-promo': 'applyPromoCode',
    'change #state': 'selectState',
    'change #province': 'selectProvince',
    'change #country': 'selectCountry',
    'click .js-cant-find-school': 'showManualForm',
    'change .js-option-org': 'selectOrgType',
  },

  modelClass: Model,

  formSelector: 'form',

  state: {
    orgType: 'teacher',
    state: $('#state').val() || '',
    province: $('#province').val() || '',
    country: $('#country').val() || '',
    canNotFindOrg: false
  },


  initialize: function(options) {
    Backbone.View.prototype.initialize.apply(this, arguments)

    if(this.order_id) {
      this.$('.js-loading').show()
      $.ajax({
        url: `/apiv1/site/order/${this.order_id}`,
        type: 'GET',
        dataType: 'json',
        error: function() {
          this.$('.js-loading').hide()
          this.$('.js-error').fastShow()
          this.initializeForm()
        }.bind(this),
        success: function(order) {
          this.showOrderDetails(order)
        }.bind(this)
      })
    } else {
      this.initializeForm()
    }

  },

  initializeForm: function() {
    this.model.url = null; // When on the license_details page we don't want to hit the backend, we'll set this when the user goes to billing_details

    this.updateTotal();
    const currentStep = parseInt(this.$('.js-current-step').val(), 10) || 1;
    this.updateStepClasses(currentStep);
    this.$('#currentStepText').text(currentStep);
    let locationCookie = Cookies.get('location') ? JSON.parse(Cookies.get('location')) : null;
    let country = locationCookie?.country;
    if(!country || !FTWGLOBALS('countries').map((row) => row[0]).includes(country) || country === 'PR' || country === 'VI') {
      country = 'US'
    }
    this.state.country = country
    if (country === 'CA') {
      let province = this.$('#province').val() || 'AB';
      this.$('#province').val(province);
    } else {
      this.$('#province').val('');
    }

    // Set the default country and state
    this.$('.js-default-country').val(country);
    if (locationCookie && locationCookie.state) {
      this.$('.js-default-state').val(locationCookie.state);
      this.state.state = locationCookie.state
    } else {
      this.$('.js-default-state').val(this.state.state);
    }
    this.updateForm()
    this.render()
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

   this.initializeSelects()

   return this
  },

  initializeSelects: function() {

    const provinceCodes = FTWGLOBALS('provinces').map(row => row[0])
    const states = FTWGLOBALS('states').filter(state => !provinceCodes.includes(state[0]) && state[0] !== 'NA' && state[0]).map(row => ({value: row[0], text: row[1]}))

    const state = this.state
    let schoolSearchResults = []
    $('#form-ele-data-school-id').selectize({
        copyClassesToDropdown: false,
        load: (query, callback) => {
            if (!query.length) return callback();
            $.ajax({
                url: '/apiv1/teacher/auth/school-search', // Adjust the URL as needed
                type: 'GET',
                dataType: 'json',
                data: {
                  term: query,
                  state: state.state
                },
                error: function() {
                  schoolSearchResults = []
                  callback();
                },
                success: function(res) {
                  schoolSearchResults = res
                  callback(res);
                }
            });
        },
        render: {
          option: (item, escape) => {
            if(!item.value) {
              return escape(item.text)
            }

            return `<div class="option">
              <span class="font-bold">${escape(item.text)}</span>
              <span class="ml-1">${escape(item.district_name)}</span>
              <span class="ml-1">${escape(item.city)} ${escape(item.state)}, ${escape(item.postal)}</span>
             </div>`
          }
        },
        onChange: (value) => this.selectSchool(schoolSearchResults.find(row => row.value === parseInt(value))),
        // The below options allow users to input their own school/district without the dropdown erasing it

        create: (input) => {
          this.$('input[name=school_name]').val(input)
          this.showManualForm()
        },
        showAddOptionOnCreate: false,
    });

    let districtSearchResults = []
    $('#form-ele-data-district-id').selectize({
        copyClassesToDropdown: false,
        load: (query, callback) => {
            if (!query.length) return callback();
            $.ajax({
                url: '/apiv1/teacher/auth/district-search', // Adjust the URL as needed
                type: 'GET',
                dataType: 'json',
                data: {
                  term: query,
                  state: state.state
                },
                error: function() {
                  districtSearchResults = []
                  callback();
                },
                success: function(res) {
                  districtSearchResults = res
                  callback(res);
                }
            });
        },
        render: {
          option: (item, escape) => {
            if(!item.value) {
              return escape(item.text)
            }

            const state = states.find(row => row.value === item.state)
            return `<div class="option">
              <span class="font-bold">${escape(item.text)}</span>
              <span class="ml-1">${state ? state.text : item.state}</span>
             </div>`
          }
        },
        onChange: (value) => this.selectDistrict(districtSearchResults.find(row => row.value === parseInt(value))),
        // The below options allow users to input their own school/district without the dropdown erasing it

        create: (input) => {
          this.$('input[name=district_name]').val(input)
          this.showManualForm()
        },
        showAddOptionOnCreate: false,
    })
},

updateForm: function() {
  const { orgType, country, canNotFindOrg } = this.state

  const domestic = country === 'US'

  const schoolManual = this.$(".js-school-manual").fastHide(),
    schoolSearch = this.$(".js-school-search").fastHide(),
    districtManual = this.$(".js-district-manual").fastHide(),
    districtSearch = this.$(".js-district-search").fastHide(),
    countryEle = this.$('.js-country').fastShow(),
    states = this.$('.js-state-search').fastHide(),
    provinces = this.$('.js-province-search').fastHide()

  if(domestic) {

    states.fastShow()

    if(orgType === 'teacher' || orgType === 'school') {
      if(canNotFindOrg) {
        schoolManual.fastShow()
      } else {
        schoolSearch.fastShow()
      }
    } else if(orgType === 'district') {
      if(canNotFindOrg) {
        districtManual.fastShow()
      } else {
        districtSearch.fastShow()
      }
    }
  } else {
    if(country === 'CA') {
      provinces.fastShow()
    }

    if(orgType === 'teacher' || orgType === 'school') {
      schoolManual.fastShow()
    } else if(orgType === 'district') {
      districtManual.fastShow()
    }
  }

},

  selectState: function(event) {
    this.state.state = $(event.target).val();
  },
  selectProvince: function(event) {
    this.state.province = $(event.target).val();
  },

  selectSchool: function(school) {
    if(!school) {
      return
    }

    this.$('input[name=school_name]').val(school.text)
    this.$('#state').val([school.state])
  },

  selectDistrict: function(district) {
    if(!district) {
      return
    }

    this.$('input[name=district_name]').val(district.text)
    this.$('#state').val([district.state])
  },


  selectCountry: function(event) {
    this.state.country = $(event.target).val();
    if($(event.target).val() !== 'US') {
      this.$('#state').val('')
    }
    if($(event.target).val() !== 'CA') {
      this.$('#province').val('')
    }

    if($(event.target).val() !== 'US') {
      this.$('input[name=manual_name]').val(1)
    } else {
      this.$('input[name=manual_name]').val(0)
    }
    this.updateForm()
  },

  showManualForm: function() {
    this.state.canNotFindOrg = true
    this.$('input[name=manual_name]').val(1)
    this.updateForm()
    if(this.state.orgType === 'school' || this.state.orgType === 'teacher') {
      this.$('input[name=school_name]').focus()
    } else if(this.state.orgType === 'district') {
      this.$('input[name=district_name]').focus()
    }
    return false
  },

  selectOrgType: function(e) {

    this.state.orgType = this.$('.js-option-org').val();
    this.state.canNotFindOrg = false
    this.$('input[name="orgType"]').val(this.state.orgType);
    this.$('input[name=manual_name]').val('')

    this.updateForm()
  },

  submitCallback: function(data) {
    const currentStepInput = this.$('.js-current-step');
    let currentStep = parseInt(currentStepInput.val(), 10);

    this.$('.js-error').fastHide()

    if (currentStep === 2) {
      this.showOrderDetails(data)
    } else {
      currentStep += 1;
      currentStepInput.val(currentStep);
      this.updateStepClasses(currentStep);
      this.updateTotal();
    }

    return false
  },

  showOrderDetails: function(order) {
    const orderView = new OrderDetailsView({ order })

    window.location.hash = `#${order.prefix}-${order.order_id}`;
    this.$('.js-quote-form').fastHide()
    this.$('.js-loading').fastHide()
    this.$('.js-price-quote-details-header').fastHide()
    this.$('.js-step-three').append(orderView.render().el)
  },

  updateStepClasses: function(currentStep) {
    this.$('.js-step-dependent-element').fastHide()
    this.$(`[data-step=${currentStep}]`).fastShow()

    $('#currentStepText').text(currentStep);

    if (currentStep === 2) {
      this.model.url = this.model.validationUrl
      this.$('.js-step-one').removeClass('bg-blue-500').addClass('bg-blue-200')
      this.$('.js-step-two').removeClass('bg-blue-200').addClass('bg-blue-500')
    } else {
      this.model.url = null
    }
  },

  resetQuoteStep: function(e) {
    e.preventDefault();
    this.$('.js-current-step').val(1);
    this.updateStepClasses(1);
  },

  showPromoInput: function() {
    this.$('.js-show-promo').addClass('hidden'); // Hide the promo button
    this.$('#promo-input').removeClass('hidden'); // Show the promo label and input
  },

  applyPromoCode: function() {
    const promoCode = this.$('#promo').val();
    if (!promoCode) return;

    $.ajax({
      url: '/apiv1/teacher/orders/validate-promo-code',
      method: 'POST',
      data: { promo_code: promoCode },
      error: function() {
        const msg = ('price_quote.invalid_promo_text'.t())
        this.$('.js-promo-error').html(msg).fastShow()
        this.$('.js-promo-success').fastHide()
      }.bind(this),
      success: function(response) {
        this.$('.js-promo-error').fastHide()
        this.$('.js-promo-success').html('price_quote.promo_success_text'.t({ promo_percent: response.discount * 100 + '%' })).fastShow()
        this.discount = response.discount;
        this.updateTotal();
      }.bind(this)
    });
  },

  updateTotal: function () {
    const seatsNumber = this.$('input[name="quantity_annual"]').val();
    const promoCode = this.$('#promo').val();
    const seats = seatsNumber ? Number(seatsNumber) : 0;
    const years = Number(this.$('select[name="months_annual"]').val()) / 12;

    this.$('.js-purchase-summary').html('price_quote.purchase_seats_text'.t({ smart_count: seats, years: years }));

    if (!seats) {
      this.$('.js-total').text('$' + 0);
      return;
    }

    // Assuming currentSeats is available or set to 0 if not applicable
    const currentSeats = 0;

    const subtotal = this.model.calculateTotalPrice(years, seats, currentSeats);
    if(this.discount > 0) {
      this.$('.js-has-promo-code').fastShow()
      this.$('.js-subtotal').text('$' + subtotal.toFixed(2));
      this.$('.js-promo-code').html('price_quote.promo_off_text'.t({ promo_code: promoCode, promo_percent: this.discount * 100 + '%' }));
      this.$('.js-discount').text('$' + (subtotal * this.discount).toFixed(2));
    }
    const total = subtotal * (1 - this.discount);

    this.$('.js-total').text('$' + total.toFixed(2));
    this.$('input[name=total_annual]').val(total.toFixed(2));
  }
})
