/**
 * Registry
 *
 * Simple get/set storage for persisting data between objects
 */
const data = [];

export default {
  /**
       * Get data stored, retrievable by key
       *
       * @param key string used to store dta
       * @returns {*}
       */
  get: function(key) {
    return data[key] || undefined;
  },


  /**
       * Set data in storage
       *
       * @param key string used to retrieve data
       * @param val
       */
  set: function(key, val) {
    data[key] = val;
  }
}
