import Backbone from 'backbone'
import $ from 'jquery'
import { isObject, concat, extend } from 'lodash-es'


/**
 * @type {Function|Backbone.Collection.reset}
 */
const oldReset = Backbone.Collection.prototype.reset,
  oldFetch = Backbone.Collection.prototype.fetch

Backbone.Collection.prototype.page = 0
Backbone.Collection.prototype.pageSize = -1
Backbone.Collection.prototype.moreRecords = false

Backbone.Collection.prototype.initialize = function(data, options) {
  extend(this, options)
};

/**
   * Redo the reset method to put a little tracker.  See hasReset() method
   */
Backbone.Collection.prototype.reset = function() {
  this._hasReset = true;

  oldReset.apply(this, arguments)
};

/**
   * See if the collection has ever been reset.
   * This is useful to show spinners if = false
   * @returns {boolean}
   */
Backbone.Collection.prototype.hasReset = function() {
  return this._hasReset || false;
};

/**
   * Overwrite the parse method for our type of data
   * @param data
   * @returns {*}
   */
Backbone.Collection.prototype.parse = function(data) {
  if (isObject(data)) {
    // Used by admin portal to show pagination
    if(data.data !== undefined && data.total !== undefined) {
      this.totalRecords = data.total;

      // Append the newly grabbed array of models onto the existing collection
      if(this.page > 0) {
        data.data = concat(this.toJSON(), data.data)
      }

      return data.data;
    }

    // Used by teacher portal for showing Load More
    if(this.pageSize > 0) {
      if(data.length > this.pageSize) {
        this.moreRecords = true
        data = data.slice(0, this.pageSize)
      } else {
        this.moreRecords = false
      }

      // Append the newly grabbed array of models onto the existing collection
      if(this.page > 0) {
        data = concat(this.toJSON(), data.slice(0, this.pageSize))
      }
    }
  }

  return data;
};

/**
   * Set the persistent local storage backend
   * @param {Function} backend The backend object
   * @param {String} resolveDataFrom backend|model Where should data initially come from.  If 'backend', then it will overwrite anything currently in the model.  And vice versa for 'model'
   * @returns {Backbone.Collection}
   */
Backbone.Collection.prototype.setBackend = function(backend, resolveDataFrom) {
  backend.setModel(this);
  this.on('change', backend.change, backend);
  this.on('add', backend.add, backend);
  this.on('remove', backend.remove, backend);
  this.on('reset', backend.reset, backend);
  this.on('sort', backend.sort, backend);

  if (resolveDataFrom == 'backend') {
    this.reset(backend.getData(), {silent: true});
  } else if (resolveDataFrom == 'model') {
    backend.setData(this.toJSON());
  }

  return this;
};

Backbone.Collection.prototype.destroyMany = function(ids) {
  var url = this.model.prototype.urlRoot;
  if(!url) {
    url = ((typeof this.url == 'string') ? this.url : this.url());
  }

  var parts = url.split('?');

  return $.ajax({
    url: parts[0] + '/many' + ((parts[1]) ? ('?'+parts[1]) : ''),
    data: JSON.stringify(ids),
    method: 'DELETE'
  }).done(function(){
    this.remove(ids);
  }.bind(this));
};

Backbone.Collection.prototype.sortField = '';
Backbone.Collection.prototype.sortDir = 'asc';

var sortingComparator = function(a, b){
  var valA = a.get(this.sortField),
    valB = b.get(this.sortField),
    dir = (this.sortDir == 'asc') ? 1 : -1;

  if(valA < valB) return -1*dir;
  if(valA > valB) return 1*dir;
  return 0;
};

var naturalSortingComparator = function naturalSorter(vala, valb){
  var as = String(vala.get(this.sortField) ? vala.get(this.sortField) : ''), // String(null) returns 'null' and we don't want that
    bs = String(valb.get(this.sortField) ? valb.get(this.sortField) : ''), // String(null) returns 'null' and we don't want that
    dir = (this.sortDir == 'asc') ? 1 : -1;

  if(typeof as != 'string' && typeof bs != 'string') {
    return sortingComparator.call(this, vala, valb);
  }

  var a, b, a1, b1, i= 0, n, L,
    rx=/(\.\d+)|(\d+(\.\d+)?)|([^\d.]+)|(\.\D+)|(\.$)/g;
  if(as=== bs) return 0;
  a= as.toLowerCase().match(rx) || '';
  b= bs.toLowerCase().match(rx) || '';
  L= a.length;
  while(i<L){
    if(!b[i]) return 1 * dir;
    a1= a[i];
    b1= b[i++];
    if(a1!== b1){
      n= a1-b1;
      if(!isNaN(n)) return n * dir;
      return (a1>b1? 1:-1) * dir;
    }
  }
  return (b[i]? -1:0) * dir;
};

Backbone.Collection.prototype.setSort = function(field, direction) {
  this.sortDir = direction || ((this.sortDir == 'asc' && this.sortField == field) ? 'desc' : 'asc');
  this.sortField = field;

  this.reSort();
};

Backbone.Collection.prototype.reSort = function() {
  if(this.comparator !== naturalSortingComparator) {
    this.comparator = naturalSortingComparator;
  }
  this.sort();
}

Backbone.Collection.prototype.fetch = function() {
  let args = Array.prototype.slice.call(arguments)

  // If paginating, embed paging data into the fetch request
  if(this.pageSize > 0) {
    // Appends pagesSize and page into data object - { xx, data: { xx: xx } }
    args[0] = {
      ...args[0],
      ...{ data: {
        ...args[0]?.data,
        ...{ pageSize: this.pageSize, page: this.page }
      }}
    }
  }

  return oldFetch.apply(this, args)
}

Backbone.Collection.prototype.getMoreRecords = function() {
  this.page++
  this.fetch()
}

Backbone.Collection.prototype.hasMoreRecords = function() {
  return this.moreRecords
}
