import Backbone from 'backbone'
import $ from 'jquery'
import Velocity from 'velocity-animate'
import { isIE11 } from '@shared/helpers'

export default Backbone.View.extend({
  el: 'body',

  events: {
    'click .js-testimonial': 'animateTestimonials',
    'click .js-dot': function(e) { this.animateTestimonials(e); return false; }
  },

  initialize: function() {
    this.animateTitle();
    this.showBenefits();
    this.fallingKeys();
    this.animateTestimonials();
  },

  showBenefits: function() {
    // Benefit Names
    var benefits = $('.js-benefit');
    var img = $('.js-benefit-video');

    // Select benefit on mouseover
    benefits.mouseover(function(e) {
      var ele = $(e.currentTarget);
      if(ele.hasClass('is-active')) { return false; }

      benefits.removeClass('is-active');
      ele.addClass('is-active');
      img.attr('src', '/dist/site_typing/images/benefits/' + ele.data('id') + '.mp4');
    });
  },

  animateTitle: function() {
    var letters = $('.typing-letter');
    var typingSpeed = 100;
    var i = 0;

    function animateTitle() {
      window.setTimeout(() => {
        letters.eq(i - 1).attr('active', false);
        letters.eq(i).attr('active', true);
        letters.eq(i).attr('typed', true);
        i++;
        if (i < letters.length) {
          animateTitle();
        }
      }, typingSpeed);
    }
    animateTitle();
  },

  /**
   * The little falling keys animation
   */
  fallingKeys: function() {
    var DELAY_BETWEEN = 2000;
    var FALLING_TIME = 9000; // default time it takes for key to fall down page.
    var MAX_ROTATION = 540; // The max amount of rotation from start to finish of the page.

    var keyboard = $('.keyboard');
    var keyContainer = $('.fallingKey-container');
    var shadowElem = keyContainer.find('.fallingKey-shadow');
    var letterIndex = 0;

    function animateKey(letter) {
      keyContainer.css('left', Math.floor(Math.random() * 95) + '%');
      keyContainer.find('.fallingKey-front').attr('data-letter', letter);
      Velocity(keyContainer,
        {
          translateY: [$('body').height() + 750 + 'px', '-500px']
        },
        {
          duration: FALLING_TIME,
          easing: 'linear',
          complete: function() {
            // after animation, do the next letter
            letterIndex++;
            if (toType[letterIndex]) {
              window.setTimeout(() => animateKey(toType[letterIndex]), DELAY_BETWEEN);
            }
          }
        }
      );

      Velocity(keyContainer.find('.fallingKey'),
        {
          rotateX: [(Math.random() - 0.5) * MAX_ROTATION + 'deg', '-10deg'],
          rotateY: [(Math.random() - 0.5) * MAX_ROTATION + 'deg', '5deg'],
          rotateZ: [(Math.random() - 0.5) * MAX_ROTATION + 'deg', '-2deg']
        },
        {
          duration: FALLING_TIME
        }
      );
    }

    function getTanFromDegrees(degrees) {
      return Math.tan((degrees * Math.PI) / 180);
    }

    if (!isIE11() && $(window).width() > 1024) {
      var letters = [];
      // grab the keys from the on screen keyboard
      $('.typing-letter').each(function(index, ele) {
        try{
          letters.push(ele.innerText.trim());
        }catch(e) { }
      });
      var toType = letters.join('');

      if (toType[letterIndex]) {
        animateKey(toType[letterIndex]);
      }
    }
  },

  animateTestimonials: function(e) {
    window.clearInterval(this.testimonialInterval);

    var dotsEle = $('.js-dots'),
      testimonialEle = $('.js-testimonial'),
      currentTestimonial = parseInt((e) ? $(e.currentTarget).attr('data-id') : dotsEle.attr('data-active')),
      newTestimonial =  (currentTestimonial === testimonialEle.length) ? 1 : currentTestimonial + 1;

    dotsEle.attr('data-active', newTestimonial);
    testimonialEle.hide();
    $('.js-testimonial[data-id=' + newTestimonial + ']').css('display', 'flex');
    this.testimonialInterval = window.setTimeout(() => this.animateTestimonials(), 7000);
  }
})
