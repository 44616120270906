import $ from 'jquery'
import 'imports-loader?jQuery=jquery,$=jquery,define=>false,module.exports=>false!jquery-validation'
import Velocity from 'velocity-animate'
import VelocityUI from 'velocity-animate/velocity.ui'
import { isObject } from 'lodash-es'
import Polyglot from 'node-polyglot'
import { isEmail } from '@shared/helpers'
import '@shared/bugsnag'
import '@shared/model'
import '@shared/view'
import '@shared/collection'

/**
 * Shared global helpers between all apps
 */

/**
 * This function takes a URL and adds proper localization prefix
 * @param url
 * @param forceUrl
 * @returns string
 */
window.__url = function __url (url, forceUrl) {
  url = url || '';

  var language = FTWGLOBALS('defaultLanguage');

  var currentSegments = window.location.pathname.split('/');
  if (currentSegments[1].match(/^[a-z]{2}(\-[a-z]{2})?$/)) {
    language = currentSegments[1];
  }

  var segments = url.split('/');

  if (forceUrl) {
    return url;

  } else if (language === FTWGLOBALS('defaultLanguage')) {
    return segments.join('/');

  } else {
    url = '/' + language + segments.join('/');
    if (url.length > 1 && url[url.length - 1] === '/') {
      return url.substr(0, url.length - 1);
    }
    return url;
  }
};

var newTabLink = function (url, text) {
  return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' +
  (text) ? text + '</a>' : ''
}

window.location.safeReload = function (removeHash = false) {
  if(removeHash) {
    window.location.href = window.location.href.replace(/#.*/, '')
  } else if (window.location.hash) {
    window.location.reload(false)
  } else {
    window.location.href = window.location.href.replace(/#$/, '')
  }
};

window.accurateInterval = function interval (duration, fn) {
  this.baseline = undefined;

  this.run = function () {
    if (this.baseline === undefined) {
      this.baseline = new Date().getTime();
    }
    fn();
    var end = new Date().getTime();
    this.baseline += duration;

    var nextTick = duration - (end - this.baseline);
    if (nextTick < 0) {
      nextTick = 0;
    }
    (function (i) {
      i.timer = window.setTimeout(function () {
        i.run(end);
      }, nextTick);
    }(this));
  };

  this.stop = function () {
    window.clearTimeout(this.timer);
  };
};

if (FTWGLOBALS('env') == 'local') {
  window.c = function (val) {
    console.log(val); // eslint-disable-line
  };
} else {
  window.c = function () {};
}

/**
 * Quick little back and forth for like a "no"
 */
Velocity.RegisterEffect('ftw.miniShake', {
  defaultDuration: 250,
  calls: [
    [{ translateX: -6 }, 0.25],
    [{ translateX: 6 }, 0.25],
    [{ translateX: -9 }, 0.25],
    [{ translateX: 0 }, 0.25]
  ],
  reset: { translateX: 0 }
});

/**
 * Tooltips
 */
Velocity.RegisterEffect('ftw.growUp', {
  defaultDuration: 200,
  calls: [
    [
      { opacity: [1, 0], scale: [1, 0], transformOriginY: ['100%', '100%'] },
      1
    ]
  ]
});

/**
 * Takes a form and returns values as key/value pairs object
 * @returns {Object}
 */
$.fn.getFormValuePairs = function () {
  var data = {};
  this.serializeArray().forEach(function (row) {
    data[row.name] = row.value;
  });
  return data;
};

/**
 * Quick and dirty hide/show methods that work on display:block/none only
 */
$.fn.fastHide = function () {
  this.each((index, ele) => {
    ele.style.display = 'none';
  });
  return this;
};
$.fn.fastShow = function (type) {
  this.each((index, ele) => {
    ele.style.display = type || 'block';
  });
  return this;
};

Array.prototype.clone = function () {
  return this.slice(0);
};

/**
 * Uppercase the first letter of a string
 * @returns {string}
 */
String.prototype.ucFirst = function () {
  return this.substr(0, 1).toUpperCase() + this.substr(1);
};

String.prototype.ucWords = function () {
  return this.replace(
    /^([a-z\u00E0-\u00FC])|\s+([a-z\u00E0-\u00FC])/g,
    function ($1) {
      return $1.toUpperCase();
    }
  );
};

String.prototype.slug = function () {
  return this.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-|-$/g, '');
};

String.prototype.t = (function () {
  var locale = FTWGLOBALS('language'),
    keysMode = window.location.search.match('keys'),
    polyglot,
    backup;

  Object.values(FTWGLOBALS('languages')).forEach(function (row) {
    if (locale === row.id) {
      polyglot = new Polyglot({
        locale: row.id,
        phrases: FTWGLOBALS('phrases')
      });
    }
  });

  return function (options) {
    if (keysMode) {
      return this;
    }

    if (typeof polyglot ===
      'undefined') return function (string) { return this; };

    if (options !== undefined && !isObject(options)) {
      console.warn('The options passed to ' + this +
        ' are not formatted correctly. Options should be an object.',
        options);
    }
    options = isObject(options) ? options : {};
    options.app_name = options.app_name || FTWGLOBALS('app_name');
    options.app_url = options.app_url || FTWGLOBALS('app_url');
    options.app_domain = options.app_domain || FTWGLOBALS('app_domain');
    options.app_hostname = options.app_hostname || FTWGLOBALS('app_hostname');

    if (backup && !polyglot.has(this)) {
      console.warn(
        this + ' translation not found.  Attempting to use backup.');
      return backup.t(this, options);
    }
    return polyglot.t(this, options);
  };
})();

String.prototype.splitByWord = function () {
  var words = [],
    wordArray = [];

  var preg = (this.match(/⏎[^\n]/)) ? /[ \n]/g : /[ \n⏎]/g,    // like above, if there are enter-then-non-newline then we don't want to split words on enter because they ARE the word (inline enter key lessons)
    splitChars = this.match(preg);

  this.split(preg).forEach(function (word, wordIndex) {
    if (word === '' && splitChars[wordIndex] === ' ') {
      word = ' '; // make this a space.  it would be a blank word if the content was padded with 2 spaces after punctuation (for that setting)
    } else if (word === '' && splitChars[wordIndex] === '⏎') {
      word = '⏎'; // make this a return. This allows for multiple concurrent returns within the typable content
    }

    word.split('').forEach(function (letter, index) {
      wordArray.push(letter);
      if (index === word.length - 1) {
        if (splitChars && splitChars[wordIndex] && letter !== ' ' &&
          letter !== '⏎') { // if it's a space or return don't add another space. this is for the 2space setting and multiple concurrent returns
          wordArray.push(splitChars[wordIndex]);
        }
        words.push(wordArray);
        wordArray = [];
      }
    });
  });

  return words;
};

/**
 * Username jquery validator
 */
$.validator.addMethod(
  'username',
  function(value, element) {
    if(isEmail(value)){
      return true;
    }
    return value
      .toLowerCase()
      .trim()
      .match(/^[a-z0-9\-._]+$/i);
  },
  'shared.validation.username'.t()
);

$.validator.addMethod( 'extension', function( value, element, param ) {
  param = typeof param === 'string' ? param.replace( /,/g, '|' ) : 'png|jpe?g|gif';
  return this.optional( element ) || value.match( new RegExp( "\\.(" + param + ")$", 'i' ) );
}, $.validator.format( 'Please enter a value with a valid extension.' ) );

$.validator.addMethod('equalsValue', function(value, element, param) {
  return value.toLowerCase() == param.toLowerCase();
}, 'shared.validation.equalsValue'.t());

// Custom JQuery validator used to validate text does not start with a wild card (*)
$.validator.addMethod(
  'noLeadingWildCard',
  function(text, element) {
    return this.optional(element) || !text.startsWith('*');
  },
  'shared.validation.noLeadingWildCard'.t()
);

// Custom JQuery validator used to validate text is JSON
$.validator.addMethod(
  'isJSON',
  function(text, element) {
    try {
      JSON.parse(text);
      return true;
    } catch (e) {
      return false;
    }
  },
  'shared.validation.isJSON'.t()
);

$.validator.addMethod(
  'lessThanField',
  function(value, element, field) {
    var parsedValue = parseInt(value);
    var otherValue = element.form[field].value;

    if (otherValue <= parsedValue) {
      return false;
    }
    return true;
  },
  'shared.validation.lessThanField'.t()
);

$.validator.addMethod(
  'min_date',
  function(value, element, field) {
    if(!value || field <= value) {
      return true;
    }
    return false;
  }
)

$.validator.addMethod(
  'alphanum',
  function(value) {
    if(!value) {
      return true;
    }
    return /^[a-zA-Z0-9]+$/.test(value);
  },
  'shared.validation.alphanum'.t()
);

$.validator.addMethod(
  'filesize',
  function(value, element, size) {
    if (element.files[0].size / 1000000 > size) {
      return false;
    }
    return true;
  },
  'shared.validation.filesize'.t()
);

$.validator.addMethod(
  'typingSpaces',
  function(value) {
    return !value.match(/\n\n/) && value.trim() !== '';
  },
  'shared.validation.typingSpaces'.t()
);

$.validator.addMethod(
  'emailExtended',
  function(value, element){
    var check = Mailcheck.run({
      email: value,
      // domains: domains,                       // optional
      // topLevelDomains: topLevelDomains,       // optional
      // secondLevelDomains: secondLevelDomains, // optional
      // distanceFunction: superStringDistance,  // optional
      // suggested: function(suggestion) {
      //     // callback code
      // },
      // empty: function() {
      //     // callback code
      // }
    });
    if(!check || $(element).data('emailExtended') === value) {
      return true;
    }
    return false;
  },
  'shared.validation.emailExtended'.t()
);

$.validator.addMethod(
  'notMatchUsername',
  function(value) {
    return value !== this.findByName('username').val();
  },
  'shared.validation.notMatchUsername'.t()
);

$.validator.addMethod(
  'ips',
  function(value) {
    if(!value.trim()) {
      return true;
    }
    return !value.trim().split("\n").filter(function(ip){
      return !ip.trim().match(/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/);
    }).length;
  },
  'shared.validation.ips'.t()
);

/**
 * English style number formatting with commas
 * @returns {string}
 */
Number.addCommas = function (num, precision = 0) {
  return Number(num).toLocaleString(undefined, { maximumFractionDigits: precision });
};

Number.prototype.addCommas = function (precision) {
  return Number.addCommas(this, precision);
};

/**
 * Convert a number of seconds into countdown timer like XX:YY;ZZ
 */
Number.prototype.countdownSeconds = function (
  forceIncludeHour, ignoreSeconds, forceDoubleDigits) {
  var h = Math.floor(this / 60 / 60),
    m = Math.floor((this - h * 60 * 60) / 60),
    s = this - h * 60 * 60 - m * 60,
    text = '';

  text =
    String(m).pad(h > 0 || forceIncludeHour || forceDoubleDigits ? 2 : 1, '0', 'STR_PAD_LEFT');
  if (!ignoreSeconds) {
    text = text + ':' + String(s).pad(2, '0', 'STR_PAD_LEFT');
  }
  if (h > 0 || forceIncludeHour) {
    text = h + ':' + text;
  }

  return text;
};

/**
 * Convert a number of seconds into countdown timer like 12 hours, 3 minutes, 6 seconds
 */
Number.prototype.englishSeconds = Number.prototype.writtenSeconds = function () {
  var h = Math.floor(this / 60 / 60),
    m = Math.floor((this - h * 60 * 60) / 60),
    s = this - h * 60 * 60 - m * 60,
    parts = [];

  if (h > 0) {
    parts.push('shared.number_hours'.t({ smart_count: h }));
  }
  if (h > 0 || m > 0) {
    parts.push('shared.number_minutes'.t({ smart_count: m }));
  }
  parts.push('shared.number_seconds'.t({ smart_count: s }));

  return parts.join(', ');
};

Number.prototype.formatSizeUnits = function formatSizeUnits () {
  var bytes = 0;
  if (this >= 1000000000) {
    bytes = (this / 1000000000).toFixed(2) + ' GB';
  } else if (this >= 1000000) {
    bytes = (this / 1000000).toFixed(2) + ' MB';
  } else if (this >= 1000) {
    bytes = (this / 1000).toFixed(0) + ' KB';
  } else if (this > 1) {
    bytes = this + ' bytes';
  } else if (this == 1) {
    bytes = this + ' byte';
  } else {
    bytes = '0 byte';
  }
  return bytes;
};

String.prototype.escapeHTML = function () {
  return $('<div>' + this + '</div>').html();
};

String.prototype.rtrim = function () {
  return this.replace(/\s+$/, '');
};

String.prototype.stripHTML = function () {
  var tmp = document.createElement('DIV');
  tmp.innerHTML = this;
  return tmp.textContent || tmp.innerText || '';
};

String.prototype.pad = function str_pad (pad_length, pad_string, pad_type) {
  var input = this;
  //  discuss at: http://phpjs.org/functions/str_pad/
  var half = '',
    pad_to_go;

  var str_pad_repeater = function (s, len) {
    var collect = '',
      i;

    while (collect.length < len) {
      collect += s;
    }
    collect = collect.substr(0, len);

    return collect;
  };

  input += '';
  pad_string = pad_string !== undefined ? pad_string : ' ';

  if (
    pad_type !== 'STR_PAD_LEFT' &&
    pad_type !== 'STR_PAD_RIGHT' &&
    pad_type !== 'STR_PAD_BOTH'
  ) {
    pad_type = 'STR_PAD_RIGHT';
  }
  if ((pad_to_go = pad_length - input.length) > 0) {
    if (pad_type === 'STR_PAD_LEFT') {
      input = str_pad_repeater(pad_string, pad_to_go) + input;
    } else if (pad_type === 'STR_PAD_RIGHT') {
      input = input + str_pad_repeater(pad_string, pad_to_go);
    } else if (pad_type === 'STR_PAD_BOTH') {
      half = str_pad_repeater(pad_string, Math.ceil(pad_to_go / 2));
      input = half + input + half;
      input = input.substr(0, pad_length);
    }
  }

  return input;
};

/**
 * Return the english ordinal for a number 1st, 2nd, etc
 */
Number.prototype.ordinal = function () {
  var s = ['th', 'st', 'nd', 'rd'],
    v = this % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
};

/**
 * Get a unix timestamp
 */
Date.getUnixTime = function () {
  return Math.floor(Date.now() / 1000);
};

/**
 * Return the plural suffix
 */
Number.prototype.pluralize = function (endsInS) {
  endsInS = endsInS || false;
  if (endsInS) {
    return this == 1 ? '' : 'es';
  }

  return this == 1 ? '' : 's';
};

String.prototype.possessive = function () {
  console.warn('Using String.prototype.possessive');
  if (this.match(/s$/i)) {
    return this + '\'';
  } else {
    if (this[this.length - 1] === 'S') {
      return this + '\'S';
    } else {
      return this + '\'s';
    }
  }
};

String.prototype.formatWithVars = function () {
  var args = arguments;
  return this.replace(/\{(\d+)\}/g, function (m, n) { return args[n]; });
};

Math.long2ip = function (num) {
  var ip = num % 256;
  for (var i = 1; i <= 3; i++) {
    num = Math.floor(num / 256);
    ip = num % 256 + '.' + ip;
  }
  return ip; // As string
};

Math.roundTenth = function (num) {
  return Math.round(10 * num) / 10;
};

// requestAnimationFrame() shim by Paul Irish
// http://paulirish.com/2011/requestanimationframe-for-smart-animating/
window.requestAnimFrame = (function () {
  return window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (/* function */ callback, /* DOMElement */ element) {
      window.setTimeout(callback, 1000 / 60);
    };
})();

/**
 * Behaves the same as setInterval except uses requestAnimationFrame() where possible for better performance
 * @param {function} fn The callback function
 * @param {int} delay The delay in milliseconds
 */
window.requestInterval = function (fn, delay) {
  if (!window.requestAnimationFrame &&
    !window.webkitRequestAnimationFrame &&
    !(window.mozRequestAnimationFrame &&
      window.mozCancelRequestAnimationFrame) && // Firefox 5 ships without cancel support
    !window.oRequestAnimationFrame &&
    !window.msRequestAnimationFrame)
    return window.setInterval(fn, delay);

  var start = new Date().getTime(),
    handle = new Object();

  function loop () {
    var current = new Date().getTime(),
      delta = current - start;

    if (delta >= delay) {
      fn.call();
      start = new Date().getTime();
    }

    handle.value = window.requestAnimFrame(loop);
  }

  handle.value = window.requestAnimFrame(loop);
  return handle;
};

/**
 * Behaves the same as clearInterval except uses cancelRequestAnimationFrame() where possible for better performance
 * @param {int|object} fn The callback function
 */
window.clearRequestInterval = function (handle) {
  window.cancelAnimationFrame ? window.cancelAnimationFrame(handle.value) :
    window.webkitCancelAnimationFrame ? window.webkitCancelAnimationFrame(
      handle.value) :
      window.webkitCancelRequestAnimationFrame
        ? window.webkitCancelRequestAnimationFrame(handle.value)
        : /* Support for legacy API */
        window.mozCancelRequestAnimationFrame
          ? window.mozCancelRequestAnimationFrame(handle.value)
          :
          window.oCancelRequestAnimationFrame
            ? window.oCancelRequestAnimationFrame(handle.value)
            :
            window.msCancelRequestAnimationFrame
              ? window.msCancelRequestAnimationFrame(handle.value)
              :
              window.clearInterval(handle);
};

/**
 * Behaves the same as setTimeout except uses requestAnimationFrame() where possible for better performance
 * @param {function} fn The callback function
 * @param {int} delay The delay in milliseconds
 */

window.requestTimeout = function (fn, delay) {
  if (!window.requestAnimationFrame &&
    !window.webkitRequestAnimationFrame &&
    !(window.mozRequestAnimationFrame &&
      window.mozCancelRequestAnimationFrame) && // Firefox 5 ships without cancel support
    !window.oRequestAnimationFrame &&
    !window.msRequestAnimationFrame)
    return window.setTimeout(fn, delay);

  var start = new Date().getTime(),
    handle = new Object();

  function loop () {
    var current = new Date().getTime(),
      delta = current - start;

    delta >= delay ? fn.call() : handle.value = window.requestAnimFrame(loop);
  }

  handle.value = window.requestAnimFrame(loop);
  return handle;
};

Element.prototype.matches = Element.prototype.matches ||
  Element.prototype.msMatchesSelector ||
  Element.prototype.webkitMatchesSelector;

/**
 * Behaves the same as clearTimeout except uses cancelRequestAnimationFrame() where possible for better performance
 * @param {int|object} fn The callback function
 */
window.clearRequestTimeout = function (handle) {
  window.cancelAnimationFrame ? window.cancelAnimationFrame(handle.value) :
    window.webkitCancelAnimationFrame ? window.webkitCancelAnimationFrame(handle.value) :
      window.webkitCancelRequestAnimationFrame ? window.webkitCancelRequestAnimationFrame(handle.value) : /* Support for legacy API */
        window.mozCancelRequestAnimationFrame ? window.mozCancelRequestAnimationFrame(handle.value) :
          window.oCancelRequestAnimationFrame	? window.oCancelRequestAnimationFrame(handle.value) :
            window.msCancelRequestAnimationFrame ? window.msCancelRequestAnimationFrame(handle.value) :
            window.clearTimeout(handle);
}

