import Backbone from 'backbone'

export default Backbone.View.extend({
  el: 'body',

  events: {
    'click .js-submit': 'redirectPage',
    'keydown .js-search-input': 'handleEnter'
  },

  initialize: function() {
    var scripts = "<script>window.REQUIRED_CODE_ERROR_MESSAGE = 'Please choose a country code'; window.EMAIL_INVALID_MESSAGE = window.SMS_INVALID_MESSAGE = 'The information provided is invalid. Please review the field format and try again.'; window.REQUIRED_ERROR_MESSAGE = 'This field cannot be left blank. '; window.GENERIC_INVALID_MESSAGE = 'The information provided is invalid. Please review the field format and try again.'; window.translation = {common: { selectedList: '{quantity} list selected', selectedLists: '{quantity} lists selected'}};var AUTOHIDE = Boolean(0);</script><script src='https://sibforms.com/forms/end-form/build/main.js'></script>"
    this.$('#footer').append(scripts);
  },

  redirectPage: function() {
    var name = this.$('#FULL_NAME').val();
    var email = this.$('#EMAIL').val();
    if (email.length > 0 && name.length > 0 && email.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/)) {
      validating();
    }

    var validating = window.setInterval(() => {
      if (this.$('#success-message').hasClass('sib-form-message-panel--active')) {
        window.clearInterval(validating);
        window.location.href = __url('/ebooks_confirmed');
      }
    }, 100);
  },

  handleEnter: function(e) {
    if (e.which === 13) {
      this.redirectPage();
      return false;
    }
  }

})
