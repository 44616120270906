import Backbone from 'backbone'
import Curriculum from './curriculum'
import CurriculumGrade from './curriculum/grade'
import Index from './index'
import Ebooks from './ebooks'
import Support from './support'
import TeachTyping from './teach-typing'
import Teachers from './teachers'
import Plus from './plus'
import Proc24 from './proc24'
import GoogleEduLicensing from './googleEduLicensing'
import PriceQuote from './price_quote'

const AppRouter = Backbone.Router.extend({
  initialize: function() {
  },

  routes: {
    '': function() {
      new Index();
    },
    'curriculum': function() {
      new Curriculum();
    },
    'curriculum/:grade': function(productId) {
      new CurriculumGrade(productId);
    },
    'ebooks': function() {
      new Ebooks();
    },
    'proc24': function() {
      new Proc24();
    },
    'google-edu-licensing': function() {
      new GoogleEduLicensing();
    },
    'support': function() {
      new Support();
    },
    'teach-typing': function() {
      new TeachTyping();
    },
    'teachers': function() {
      new Teachers();
    },
    'plus': function() {
      new Plus();
    },
    'price-quote': function() {
      const orderId = window.location.hash.replace('#', '').split('-')

      new PriceQuote({ order_id: orderId[1] });
    }
  }
})

export default {
  initialize: function() {
    new AppRouter();
    var parts = window.location.pathname.split('/'),
      // if index 1 (2nd val) is 2 chars, then they have a language on the URL like /es
      root = Object.values(FTWGLOBALS('languages')).filter(function(row) { return row.id === parts[1]; }).length > 0 ? parts[1] : '';

    Backbone.history.start({pushState: true, root: root});
  }
}
