import Bugsnag from "@bugsnag/js"

const messageBlacklist = [
  'NS_ERROR_NOT_INITIALIZED',
  'eyereturn',
  'moatads',
  "expected expression, got '<'",
  "JSON Parse error: Unrecognized token '<'",
  'NS_ERROR_FILE_NO_DEVICE_SPACE',
  'ad-score',
  'texture is null',
  'textplugin',
  'innovid',
  'flashtalking',
  '3lift',
  'tubemogul',
  'ampproject',
  'grecaptcha',
  '__evt_name',
  'googleapis.com',
  'adform.net',
  'celtra.com',
  'pub.network',
  'yahoo',
  'pictela',
  'upapi',
  'Unexpected end of script',
  'adsafeprotected.com',
  '.data.indexOf is not a function',
  'See almond README',
  'PulsePointVideo',
  'ResizeObserver',
  'extAbbr',
  'sendBeacon',
  'googlesyndication',
  'PAPADDINGXXPADDING',
  'Microsoft Internet extensions',
  'The operation was aborted',
  'Blocked a frame',
  'json_masks',
  'out of memory',
  'lnrAppboy',
  'network_failure',
  'NS_ERROR',
  'NotSupportedError',
  'advVis', // ads
  'processEvent', // ads
  'Unexpected token <', // ads or plugins
  'Unexpected token \'<\'', // ads or plugins
  'Syntax error', // ads
  '\'tagName\' of null', // ads i think
  'Unable to decode audio data', // game error that isn't really important
  '\'cmd\'', // ads
  'play() request', // ads
  'BetterJsPop',  //ads
  'initEvent', // ads
  't.country',  // freestar ads problem it seems
  'reachGoal', // some hax from https://patka.ru/cc or something
  '$ is not defined', // who knows
  'requestAnimationFrame', // samsung internet phone
  'offsetHeight\' of null', // popper thing not worth fixing
  'appendChild\' of null',  // popper again
  'NS_ERROR_FAILURE', // ads i think
  'lineNumber must be a Number',  // bugsnag error actually
  'Rejection reason was not an Error', // something thrown from bugsnag
  'UET is', 'variable: UET', '\'UET\'', // error in bing tracking code when script is blocked i think
  'InvalidStateError: The object is in an invalid state.',   // mobile safari error that seems to have to do with playing videos
  'wordAtPosition', // Maybe cheaters who spam the system
  'l is null', // Phaser fails to load a texture
  'event is not defined', // Most likely due to an AD
  'Cannot track in/out of view for unset ref', // Most likely an Ad
  'aiv::err', // Most likely an Ad
  'tapad',
  'Tapad',
  'ID5 is not defined',
  't.creative is undefined',
  '__tcfapiCall',
  'unhandledrejection',
  'ID5',
  "null is not an object (evaluating 'e.message')", // Crazy Phaser issue
  "hotjar",
  'static.criteo.net',
  'warp.media.net',
  'ezslots_raw',
  'tlAdhesion',
  'randomUUID',
  '__IS_FULL_VERSION__',
  'postMessage',
  'this.getContainer() is null'
];
const traceBlacklist = [
  'textplugin',
  'ampproject',
  'grecaptcha',
  '__evt_name',
  'googleapis.com',
  'adform.net',
  'celtra.com',
  'extAbbr',
  'zencdn.net',
  'static.userback.io',
  'adservice.google.com',
  'vjs.zencdn',
  'doubleclick',
  'googleadservices',
  'googletagservices',
  'connect.facebook',
  'apis.google.com',
  'googlesyndication',
  '127.0.0.1',
  'www.google-analytics.com',
  'newrelic',
  'cloudfront',
  'nr-data',
  'global code',
  'unknown method',
  'github', // plugin? ad?
  'moz-extension',  // plugins?
  'pub.network',  // ads
  'mraid', // ads
  'global code:1:1',  // dunno..
  'iOSInlineMediaControlsRecognizedTapGesture',
  'bootstrap/language',   //spiders blocked by CF error out on this file
  'nuanria.FrameConnector',
  'CrossScreenBannerFormat', // freestar ad
  'secure-ds.serving-sys.com', // Ad?
  'innovid', // Ad?
  '3lift',
  'flashtalking',
  'tubemogul',
  'AnimatedBannerFormat', // Ad?
  'native.sharethrough.com',
  'd.cp.yahoo.com',
  'securepubads',
  'freakarcade.com',
  'a.pub.network',
  'cdn.doubleverify.com',
  'adlightning.com',
  'spotxcdn.com',
  'freestar-io',
  'moatads',
  'yimg.com',
  'viglink',
  'acdn.adnxs.com',
  'ads-us.pictela',
  'static-eb.media.net',
  'btloader.com', // Cloudflare
  'ads.pubmatic',
  'runAppList',
  'proper.io',
  'amazon-adsystem',
  'googletagmanager',
  'quantserve.com',
  'AudioFile.js',
  'https://cdn.',
  'ceCurrentVideo.currentTime',
  'js.ad-score.com',
  'ats.rlcdn.com',
  'cdn-view',
  "hotjar",
  'popper',
  'window.ats',
  'ezojs',
  'astonishingfood.com',
  'randomUUID',
  'invstatic101',
  'NS_ERROR_NOT_INITIALIZED',
  '__IS_FULL_VERSION__',
  'boq-content-ads-contributor'
];
const urlBlacklist = [
];

export default {

  init: (metadata = {}) => {
    Bugsnag.start({
      apiKey: FTWGLOBALS('bugsnagKey'),
      appVersion: FTWGLOBALS('cacheId'),
      autoTrackSessions: false,
      releaseStage: FTWGLOBALS('env'),
      enabledReleaseStages: [ 'production', 'stage' ],
      enabledBreadcrumbTypes: [
        'navigation', 'request', 'process', 'log', 'user', 'state', 'error', 'manual'
      ],
      metadata: metadata,
      onError: (event) => {
        const errors = event.errors

        if (window.navigator.isFailBrowser) {
          return false;
        }

        // sample 10% of ztype bugs just to get a feel for what's going on
        if (window.window.location.href.match('ztype') && Math.random() >= 0.1) {
          return false;
        }

        // Loop through each error and discard any we don't like
        for(let i = 0; i < errors.length; i++) {
          const error = errors[i],
            stack = JSON.stringify(error.stacktrace)

          let j = 0

          for (j = 0; j < messageBlacklist.length; j++) {
            if (error.errorMessage && error.errorMessage.indexOf(messageBlacklist[j]) > -1) {
              return false;
            }
          }

          for (j = 0; j < traceBlacklist.length; j++) {
            if (stack && stack.indexOf(traceBlacklist[j]) > -1) {
              return false;
            }
          }

          for (j = 0; j < urlBlacklist.length; j++) {
            if (window.location.href.indexOf(urlBlacklist[j]) > -1) {
              return false;
            }
          }
        }
      }
    })

    Bugsnag.leaveBreadcrumb('App starting…')
  }
}
