import Backbone from 'backbone'
import { extend } from 'lodash-es'

const ACTIVITY_TIMEOUT_DURATION = 29.5 * 60 * 1000; // 29 minutes, 30 seconds
let activityTimeout = null;

export default extend({

  /**
   * Sets a timer that fires an inactivity event whenever the user hasn't hit Google Analytics within a certain duration of time
   */
  setActivityTimeout: function() {
    if(activityTimeout) {
      window.clearTimeout(activityTimeout);
    }

    activityTimeout = window.setTimeout(() => this.trigger('activityTimeout'), ACTIVITY_TIMEOUT_DURATION);
  },

  // REMOVE ON 7/1/2023
  customDimension: function(index, value) {
    ga('set', 'dimension' + index, value);
  },

  // REMOVE ON 7/1/2023
  customMetric: function(index, value) {
    ga('set', 'metric' + index, value);
  },

  // REMOVE ON 7/1/2023
  setUserId: function(userId) {
    ga('set', 'userId', userId);
  }
  
}, Backbone.Events)
