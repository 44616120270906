import Backbone from 'backbone'
import { curriculum_grade } from '@templates/site_typing'
import Registry from '@registry';

export default Backbone.View.extend({

  template: curriculum_grade,

  events: {
    'click .js-unit-accordion': 'accordionClick'
  },

  initialize: function({ productId }) {
    Backbone.View.prototype.initialize.apply(this, arguments)

    this.productId = this.mapLangAndTransformGrade(productId);
  },

  mapLangAndTransformGrade: function(value) {
    // Map for language transformations
    const langMap = {
      'espanol': 'typing_es',
      'portuguese': 'typing_br',
      'adult_learner': 'typing',
      'kindergarten': 'grade_k'
    };

    // Check if the value exists in langMap, if yes return the mapped value
    if (langMap[value]) {
      return langMap[value];
    }

    return value
  },

  serialize: function() {
    return {
      units: FTWGLOBALS('units').filter(row => row.product_id === this.productId && row.type !== 'custom' && row.type !== 'test').sort((a, b) => a.display_order - b.display_order),
      lessons: FTWGLOBALS('lessons').filter(row => row.product_id === this.productId).sort((a, b) => a.sort_order - b.sort_order)
    }
  },

  accordionClick : function(e) {
    const accordionElem = this.$(e.currentTarget),
      id = accordionElem.attr('data-id'),
      accordionElems = this.$('.js-unit-accordion');

    if(!accordionElem.hasClass('is-active')) {
      accordionElems.removeClass('is-active');
      accordionElem.addClass('is-active');
    } else {
      accordionElems.removeClass('is-active');
    }
  },
})
