import $ from 'jquery'
import Registry from '@registry'

export default Backbone.Model.extend({
  /**
   * This can be set to an HTML form element, so overwritten sync can upload files
   */
  fileUpload: null,

  /**
   * This can be set to true to override server if they're offline
   */
  offlineSupport: false,

  validationRules: {

  },
  validationMessages: {

  },

  sync: function() {
    if(this.offlineSupport && !Registry.get('loggedIn')) {
      return $.Deferred().resolve(this.toJSON());
    }

    if(!this.fileUpload) {
      return Backbone.Model.prototype.sync.apply(this, arguments);
    }

    var method = 'post',
      url;

    if(this.id) {
      method = 'patch';
      if(this.urlRoot) {
        url = this.urlRoot + '/' + this.id;
      } else if (typeof this.url == 'function') {
        url = this.url();
      } else {
        url = this.url;
      }
    } else {
      if(this.urlRoot) {
        url = this.urlRoot;
      } else if (typeof this.url == 'function') {
        url = this.url();
      } else {
        url = this.url;
      }
    }

    return $.ajax({
      url: url,
      method: 'POST',
      data: new FormData($(this.fileUpload)[0]),
      dataType: 'json',
      processData: false,
      contentType: false,
      headers: {'X-HTTP-METHOD-OVERRIDE': method}
    }).done(function(data) {
      this.set(data);
    }.bind(this));
  }
})
