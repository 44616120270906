import Backbone from 'backbone'
import $ from 'jquery'
import MainView from './views/grade'
import 'imports-loader?jQuery=jquery,$=jquery,define=>false,module.exports=>false!chosen-js'

const COMMON_CORE_STATES = ["al","ar","ca","co","ct","de","ga","hi","id","il","ia","ks","ky","la","me","md","ma","mi","ms","mo","mt","nv","nh","nm","ny","nc","nd","oh","or","pa","ri","sd","tn","ut","vt","wa","wv","wi","wy"]
const ISTE_STATES = ["al","ar","ca","co","ct","de","ga","hi","id","il","ia","ks","ky","la","me","md","ma","mi","mn","ms","mo","mt","nv","nh","nm","ny","nc","nd","oh","or","pa","ri","sd","tn","ut","vt","wa","wv","wi","wy"]
const CUSTOM_STATES = {
  "ak": "https://www.typing.com/resources/standards-alignment/alaska-standards-correlations.pdf",
  "az": "https://www.typing.com/resources/standards-alignment/arizona-standards-correlations.pdf",
  "fl": "https://www.typing.com/resources/standards-alignment/florida-standards-correlations.pdf",
  "in": "https://www.typing.com/resources/standards-alignment/indiana-standards-correlations.pdf",
  "mn": "https://www.typing.com/resources/standards-alignment/minnesota-standards-correlations.pdf",
  "na": "https://www.typing.com/resources/standards-alignment",
  "ne": "https://www.typing.com/resources/standards-alignment/nebraska-standards-correlations.pdf",
  "nj": "https://www.typing.com/resources/standards-alignment/new-jersey-standards-correlations.pdf",
  "ok": "https://www.typing.com/resources/standards-alignment/oklahoma-standards-correlations.pdf",
  "sc": "https://www.typing.com/resources/standards-alignment/south-carolina-standards-correlations.pdf",
  "tx": "https://www.typing.com/resources/standards-alignment/texas-teks-correlations.pdf",
  "va": "https://www.typing.com/resources/standards-alignment/virginia-state-standards-correlations.pdf"
}

export default Backbone.View.extend({

  el: 'body',

  mapClickable: false,

  events: {
    'click .js-modal-trigger': 'openModal',
    'click .js-modal-close': 'closeModal',
    'click .js-unit-card-toggle': 'toggleCard',

    // Map Feature
    'click .js-standards-button': 'openStandards',
    'click .js-map a': 'mapClick',
    'click .js-map-button': 'unlockMap',
    'change select[name=states]': 'stateChanged',

    // FAQ Feature
    'click .js-accordion': 'accordionClick'
  },

  initialize: function(productId) {
    Backbone.View.prototype.initialize.apply(this, arguments)

    $('#curriculum').append(new MainView({ productId }).render().el);
  },

  render: function() {
    this.$('.chosen-select-states').chosen({disable_search_threshold: 10, search_contains: true }).data('chosen');

    Backbone.View.prototype.render(this, arguments)

    return this
  },

  toggleCard: function(e) {
    const ele = this.$(e.currentTarget)

    ele.toggleClass('is-open')
  },

  unlockMap: function() {
    this.mapClickable = true

    this.$('.js-map').children('a').addClass('hover:text-blue-600')
    this.$('.js-map-button').fastHide()
  },

  openStandards: function(e) {
    const state = this.$('.js-state-select').val(),
      id = this.$(e.currentTarget).data('id')

    if(id === 'state') {
      window.open(CUSTOM_STATES[state.toLowerCase()])
    } else if(id === 'iste') {
      window.open('https://www.typing.com/resources/standards-alignment/iste-standards-correlations.pdf')
    } else {
      window.open('https://www.typing.com/resources/standards-alignment/common-core-standards-correlations.pdf')
    }
  },

  mapClick: function(e) {
    const stateEle = $(e.currentTarget).children('.state'),
      state = (stateEle[0].id || 'state-').split('-')[1].toUpperCase(),
      selectEle = this.$('.js-state-select')

    if(!this.mapClickable) {
      this.unlockMap()
    }

    selectEle.val(state).trigger('chosen:updated')
    this.stateChanged()

    e.preventDefault()

    return false
  },

  stateChanged: function() {
    const state = this.$('select[name=states]').val(),
      standardsEle = this.$('.js-standards')

    if(!state) {
      standardsEle.fastHide()
      return false
    }

    if(!this.mapClickable) {
      this.unlockMap()
    }

    this.$('.state-color').removeClass('state-color-active')
    $(`#state-${state}`).addClass('state-color-active')

    this.$('.js-standards-button').fastHide()

    if(CUSTOM_STATES[state.toLowerCase()]) {
      this.$('.js-standards-button[data-id="state"]').html('teachers.state_correlation'.t({ state: FTWGLOBALS('states').find(row => row[0] === state)[1] }) + ' &#187;')
      this.$('.js-standards-button[data-id="state"]').fastShow()
    }
    if(ISTE_STATES.includes(state.toLowerCase())) {
      this.$('.js-standards-button[data-id="iste"]').fastShow()
    }
    if(COMMON_CORE_STATES.includes(state.toLowerCase())) {
      this.$('.js-standards-button[data-id="common-core"]').fastShow()
    }

    this.$('.js-standards').fastShow()
  },

  accordionClick : function(e) {
    const accordionElem = this.$(e.currentTarget),
      id = accordionElem.attr('data-id'),
      accordionElems = this.$('.js-accordion');

    if(!accordionElem.hasClass('is-active')) {
      accordionElems.removeClass('is-active');
      accordionElem.addClass('is-active');
    } else {
      accordionElems.removeClass('is-active');
    }
  },
})
