import Backbone from 'backbone'
import $ from 'jquery'

export default Backbone.View.extend({
  el: 'body',

  events: {
    // FAQ Feature
    'click .js-accordion': 'accordionClick'
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.showBenefits();
  },

  showBenefits: function() {
    var benefits = $('.js-benefit');
    // Select benefit on mouseover
    benefits.mouseover(function(e) {
      var id = $(e.currentTarget).attr("data-id"),
      section = $(e.currentTarget).attr("data-section"),
      image = $(e.currentTarget).css("background-image")

      $('.js-benefit[data-section=' + section + ']').removeClass("is-active")
      $('.js-benefit-image[data-id=' + id + ']').attr("style", "background-image: " + image + ")")
      $(e.currentTarget).addClass("is-active")
    });
  },

  accordionClick : function(e) {
    const accordionElem = this.$(e.currentTarget),
      id = accordionElem.attr('data-id'),
      accordionElems = this.$('.js-accordion');

      if(!accordionElem.hasClass('is-active')) {
        accordionElems.removeClass('is-active');
        accordionElem.addClass('is-active');
      } else {
        accordionElems.removeClass('is-active');
      }
  },
})
