const getEvalStr = (tmplData) => { if(Object.keys(tmplData).length > 0) { return 'var ' + Object.keys(tmplData).map((key) => key + ' = tmplData["' + key + '"]').join(',') + ';' } else { return '' } };export const shared_chart = function(tmplData) {  eval(getEvalStr(tmplData));
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="chart card card--nobs">\n  <h3 class="well well--b well--xs tac tc-t">' +
__e(title) +
'</h3>\n  ';
if(toggles.length > 1) {;
__p += '\n  <ul class="chart-list list list--inline list--f list--c well well--b well--xs">\n    ';
toggles.forEach(function(toggle) {;
__p += '\n    <li class="list-item chart-tab js-toggle ' +
__e((toggle === selectedToggle ? 'is-active' : '')) +
'" data-id="' +
__e(toggle) +
'">' +
__e(('shared.chart.toggle_label_' + toggle).t()) +
'</li>\n    ';
});
__p += '\n  </ul>\n  ';
};
__p += '\n  <canvas id="js-chart-' +
__e(chartId) +
'" height="' +
__e(height) +
'" width="' +
__e(width) +
'"></canvas>\n</div>\n';
return __p
};export const shared_confirm_with_input = function(tmplData) {  eval(getEvalStr(tmplData));
var __t, __p = '', __e = _.escape;
__p += '<form>\n  <p>' +
__e(description) +
'</p>\n  <p class="mt-4">' +
((__t = ('shared.confirm_input_text'.t({
    confirmText: `<span class='font-medium text-red-600 uppercase'>${'shared.confirm_text'.t()}</span>`
  }))) == null ? '' : __t) +
'</p>\n  ' +
((__t = (FORM.textGroup('confirm_text', '', '', null, ['text-center text-red-600 font-medium uppercase']))) == null ? '' : __t) +
'\n</form>';
return __p
};export const shared_keyboard_helper_identified = function(tmplData) {  eval(getEvalStr(tmplData));
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

if(keyboard) {;
__p += '\n  <div class="list list--xxs js-panel-content">\n    <p class="well well--f">' +
__e((inClass) ? 'shared.lesson.identified_keyboard_inclass_text'.t() : 'shared.lesson.identified_keyboard_text'.t()) +
'</p>\n    <p class="tac tfh well well--xs">' +
__e(keyboard.name) +
'</p>\n    <div class="keyboard keyboard--a keyboard--c keyboard--full well well--b">\n      ';
keyboard.structure.forEach(function(row){;
__p += '\n        <div class=\'keyboard-row\'>\n          ';
row.forEach(function(key){
            var classes = ['key-'+key.main.code],
                keyLabels = (key.main.key || '').split(" ");

            if(!lodash.isObject(key.main)) { // data can be like "main":"s", make it "main": {key:"s", "code":155}
              key.main = {
                key: key.main
              };
            }
            if(!key.main.code && key.main.key){
              key.main.code = key.main.key.charCodeAt(0);
            }
            var classes = ['key-'+key.main.code],
                keyLabels = (key.main.key || '').split(" ");
    
            if(keyLabels.length > 1) classes.push('key--twoWords');
            if(key.other) classes.push('key--special');
            if(key.main.code == '17' || key.main.code == '18' || !key.other) classes.push('key--rspnsv');
            if(key.shifted && key.shifted.key) classes.push('key--duo');
            if(key.other && key.right) classes.push('key--special-r');
            if(key.other && key.left) classes.push('key--special-l');
            if(key.right) classes.push('right');
            if(key.left) classes.push('left');
            if(key.hidden && key.hidden.key) classes.push('key-'+key.hidden.code);
            if(key.shifted && key.shifted.key) classes.push('key-'+key.shifted.code);
            if(key.alt && key.alt.key) classes.push('key-'+key.alt.code);
            ;
__p += ' \n            <div class="keyboard-key key key--a  key--s ' +
__e(classes.join(' ')) +
'" ' +
((__t = ((key.width)?('style="flex-basis:'+key.width+'px"'):'')) == null ? '' : __t) +
'>\n              ';
if(key.shifted && key.shifted.key){;
__p += ' \n                <div class=\'key-label\'>' +
__e(key.shifted.key) +
'</div>\n              ';
};
__p += '\n              <div class=\'key-label\'>\n                ';

                if(keyLabels.length > 1) {
                  keyLabels.forEach(function(kl, i){
                    ;
__p += '<span class="key-label--' +
__e(i) +
'">' +
__e(kl) +
'</span>';

                  });
                } else {;
__p += '\n                  ' +
__e(key.main.key) +
'\n                ';
};
__p += '\n              </div>\n            </div>\n          ';
});
__p += '\n        </div>\n      ';
});
__p += '\n    </div>\n  </div>\n';
};

return __p
};export const shared_keyboard_helper_languages = function(tmplData) {  eval(getEvalStr(tmplData));
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="well well--b js-panel-content">\n  <div class="list list--xxs" style="column-count:2">\n    ';
Object.values(keyboardLanguages).forEach(function(language, index) {;
__p += '\n      <div tabindex="0" class="list-item link link--language js-keyboard-language ' +
__e((language.id === selectedLanguageId)?'is-active':'') +
'" data-id="' +
__e(language.id) +
'">\n        <div class=\'bucket bucket--flag bucket--xs\'>\n          <div class=\'bucket-media\'>\n            <img src="/dist/shared/images/flags/' +
__e(language.flag) +
'.png">\n          </div>\n          <div class=\'bucket-content\'>\n            <span>' +
((__t = (language.name)) == null ? '' : __t) +
'</span>\n            ';
if(language.native_name){;
__p += '\n              <span class="tc-ts tss"> (' +
__e(language.native_name) +
')</span>\n            ';
};
__p += '\n          </div>\n        </div>\n      </div>\n    ';
});
__p += '\n  </div>\n</div>';
return __p
};export const shared_keyboard_helper_modal = function(tmplData) {  eval(getEvalStr(tmplData));
var __t, __p = '', __e = _.escape;
__p += '<form>\n  ' +
((__t = (FORM.hidden('keyboard_id', keyboardId))) == null ? '' : __t) +
'\n</form>\n<div class="modal-content js-panel"></div>\n<div class="row">\n  <div class="modal-footer split">\n    <div class="split-cell">\n      <button class="js-back btn btn--stroke-s">\n        ' +
__e('shared.cancel_text'.t()) +
'\n      </button>\n    </div>\n    <div class="split-cell">\n      <button class="js-next btn btn--a">\n        ' +
__e('shared.next'.t()) +
'\n      </button>\n    </div>\n</div>\n</div>';
return __p
};export const shared_notice = function(tmplData) {  eval(getEvalStr(tmplData));
var __t, __p = '', __e = _.escape;
__p += '<div class="notice notice--s ' +
__e((error) ? 'notice--error' : 'notice--success') +
' js-notice">\n  <div class="notice-content">' +
__e(text) +
'</div>\n</div>';
return __p
};export const shared_keyboard_helper_keyboards = function(tmplData) {  eval(getEvalStr(tmplData));
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="list list--xxs js-panel-content">\n  <p class="well well--b well--xs">' +
__e('shared.lesson.identify_keyboard_text'.t()) +
'</p>\n  <div class="row row--f">\n    <div class="g g--c g--s well well--b">\n      ';
languageKeyboards.forEach(function(keyboard, index) {;
__p += '\n        <div class="g-b g-b--4of12 well well--t well--xs">\n          <div tabindex="0" class="keyboard keyboard--placement js-keyboard-placement ' +
__e(((selectedKeyboardId === null && index === 0) || keyboard.keyboard_id === selectedKeyboardId)?'is-active':'') +
'" data-id="' +
__e(keyboard.keyboard_id) +
'">\n            <div class="keyboard-placementContents">\n              ';
keyboard.minifiedStructure.forEach(function(row, rowIndex){;
__p += '\n                <div class=\'keyboard-row\'>\n                  ';
row.forEach(function(key){
                    key.width || 40
                  });
__p += '\n                  ';
if(rowIndex <= 1){;
__p += '\n                    ';
totalKeyWidth = row.reduce(function(acc, cur) {
                      return acc + parseInt(cur.width || 40);
                    }, 0) + ((rowIndex == 0) ? 18 : 0);
__p += '\n                    <div class="key key--spacer" style="flex-basis: ' +
__e(165 - totalKeyWidth) +
'px" totalKeyWidth="' +
__e(totalKeyWidth) +
'"></div>\n                  ';
};
__p += '\n                  ';
row.forEach(function(key){
                    if(!lodash.isObject(key.main)) { // data can be like "main":"s", make it "main": {key:"s", "code":155}
                      key.main = {
                        key: key.main
                      };
                    }
                    if(!key.main.code && key.main.key){
                      key.main.code = key.main.key.charCodeAt(0);
                    }
                    var classes = ['key-'+key.main.code],
                        keyLabels = (key.main.key || '').split(" ");
            
                    if(keyLabels.length > 1) classes.push('key--twoWords');
                    if(key.other) classes.push('key--special');
                    if(key.main.code == '17' || key.main.code == '18' || !key.other) classes.push('key--rspnsv');
                    if(key.shifted && key.shifted.key) classes.push('key--duo');
                    if(key.other && key.right) classes.push('key--special-r');
                    if(key.right) classes.push('right');
                    if(key.hidden && key.hidden.key) classes.push('key-'+key.hidden.code);
                    if(key.shifted && key.shifted.key) classes.push('key-'+key.shifted.code);
                    ;
__p += ' \n                    <div class="keyboard-placementKey key key--placement key--a ' +
__e(classes.join(' ')) +
'" ' +
((__t = ((key.width)?('style="flex-basis:'+key.width+'px"'):'')) == null ? '' : __t) +
'>\n                      ';
if(key.shifted && key.shifted.key){;
__p += ' \n                        <div class=\'key-label\'>' +
__e(key.shifted.key) +
'</div>\n                      ';
};
__p += '\n                      <div class=\'key-label\'>\n                        ';

                        if(keyLabels.length > 1) {
                          keyLabels.forEach(function(kl, i){
                            ;
__p += '<span class="key-label--' +
__e(i) +
'">' +
__e(kl) +
'</span>';

                          });
                        } else {;
__p += '\n                          ' +
__e(key.main.key) +
'\n                        ';
};
__p += '\n                      </div>\n                    </div>\n                  ';
});
__p += '\n                </div>\n              ';
});
__p += '\n            </div>\n            <div class="keyboard-placementTitle">' +
((__t = (keyboard.name)) == null ? '' : __t) +
'</div>\n          </div>\n        </div>\n      ';
});
__p += '\n    </div>\n  </div>\n</div>';
return __p
};