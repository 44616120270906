import Backbone from 'backbone'

export default Backbone.View.extend({
  el: 'body',

  events: {
    'click .js-accordion': 'accordionClick',
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments)

    this.render()
  },

  accordionClick : function(e) {
    const accordionElem = this.$(e.currentTarget),
      accordionElems = this.$('.js-accordion');

      if(!accordionElem.hasClass('is-active')) {
        accordionElems.removeClass('is-active');
        accordionElem.addClass('is-active');
      } else {
        accordionElems.removeClass('is-active');
      }
  }

})
