import Backbone from 'backbone'
import { price_quote_order_details } from '@templates/site_typing'

export default Backbone.View.extend({

  template: price_quote_order_details,

  events: {
    'click .js-copy': 'copyLink'
  },

  initialize: function(options) {
    this.order = options.order;
    try {
      this.orderData = JSON.parse(this.order.form_data);
    } catch(e) {
      this.orderData = this.order;
    }
    this.orderData.order_id = this.order.order_id;
  },

  serialize: function() {
    return {
      order: this.orderData,
      language: FTWGLOBALS('language'),
      teachersAppUrl: FTWGLOBALS('teachersAppUrl')
    }
  },

  copyLink: function() {
    this.$('.js-copy-notification').fadeIn();
    setTimeout(() => {
      this.$('.js-copy-notification').fadeOut();
    }, 1000);

    navigator.clipboard.writeText(`${window.location.origin}/price-quote#${this.orderData.prefix}-${this.orderData.order_id}`)
  }
})
