import Backbone from 'backbone'
import $ from 'jquery'
import 'imports-loader?jQuery=jquery,$=jquery,define=>false,module.exports=>false!chosen-js'

export default Backbone.View.extend({
  el: 'body',

  events: {
    'click .js-accordion': 'accordionClick',
    'submit #quoteForm': 'handleFormSubmit'
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments)

    this.render()
  },

  accordionClick : function(e) {
    const accordionElem = this.$(e.currentTarget),
      id = accordionElem.attr('data-id'),
      accordionElems = this.$('.js-accordion');

      if(!accordionElem.hasClass('is-active')) {
        accordionElems.removeClass('is-active');
        accordionElem.addClass('is-active');
      } else {
        accordionElems.removeClass('is-active');
      }
  },

  handleFormSubmit: function (e) {
    e.preventDefault();

    let formData = {};
    const formArray = this.$('form#quoteForm').serializeArray();
    $.each(formArray, function() {
      formData[this.name] = this.value;
    });

    $.ajax({
      url: '/apiv1/site/contact/price-quote',
      method: 'POST',
      data: formData,
      success: function() {
          const successMessage = $('#successMessage');
          $('form#quoteForm .js-form-container').fadeOut(function() {
             successMessage.fadeIn();
          });
      },
      error: () => {}
    });
  },
})