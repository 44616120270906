import Backbone from 'backbone';
import $ from 'jquery';
import { rot13 } from '@shared/helpers';
import 'imports-loader?jQuery=jquery,$=jquery,define=>false,module.exports=>false!chosen-js';
import Registry from '@registry';

const model = Backbone.Model.extend({
  url: undefined,

  validationRules: {
    name: {
      required: true,
    },
    email: {
      required: true,
      email: true,
    },
    quantity_annual: {
      required: true,
      number: true,
    },
  },
});

export default Backbone.View.extend({
  el: 'body',

  formSelector: '.js-form',

  modelClass: model,

  events: {
    'input input[name=quantity_annual]': 'updateTotal',
    'change select[name=months_annual]': 'updateTotal',
  },

  initialize: function () {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.updateTotal();
    this.render();
  },

  updateTotal: function () {
    const seatsNumber = this.$('input[name="quantity_annual"]').val();
    const seats = seatsNumber ? Number(seatsNumber) : 0;
    const years = Number(this.$('select[name="months_annual"]').val()) / 12;

    if (!seats) {
      this.$('#total').text('$' + 0);
      return;
    }

    const tier = FTWGLOBALS('pricingProc24').
      find(([min, max]) => seats >= min && seats <= max);
    const seatYearCost = tier ? tier[2] : 0;

    const total = seats * years * seatYearCost;

    this.$('.js-total').text('$' + total.addCommas(2));
    this.$('input[name=total_annual]').val(total.toFixed(2));
  },

  buttonClick: function (data) {
    this.$('input[name=created_at]').val(Math.round(Date.now() / 1000))
    const formElements = rot13(this.$('.js-form').serialize());

    if (this.validator.form.valid()) {
      window.open(`/apiv1/site/order/teks/pricequote?${formElements}`, '_blank')
      this.$('.js-form').hide()
      this.$('.js-thank-you').fastShow()
    }

    return false;
  }

});
