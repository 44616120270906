import $ from 'jquery'
import Velocity from 'velocity-animate'
import VelocityUI from 'velocity-animate/velocity.ui'
import { random, times, forEach } from 'lodash-es';
import moment from 'moment'

export const mapLanguageToLibrary = function (library, language) {
  var libMap = FTWGLOBALS('languageLibraryMap')[library];
  return (libMap && libMap[language]) ? libMap[language] : language;
};

/**
 * Just like $.post but does it as a JSON thing
 * @param url
 * @param data
 * @returns {*}
 */
export const postJSON = function (url, data) {
  return $.ajax({
    type: 'POST',
    url: url,
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    data: JSON.stringify(data)
  });
};

/**
 * Bounce to the login screen
 * @param module student|teacher
 */
export const loginRedirect = function (module) {
  var currentURL = parseURL(window.location.href);
  if (currentURL.pathname !== __url('/' + module + '/login')) {
    window.location.href = __url('/' + module + '/login' + '#' + encodeURI(
      'r:' + currentURL.pathname + currentURL.search + currentURL.hash));
  } else {
    window.location.reload();
  }
};

export const playSound = function (sound) {
  var promise = sound.play();
  if (promise && promise.catch) {
    promise.catch(function (e) {}); // prevent no-autoplay error
  }
};

export const parseURL = function (url) {
  var parser = document.createElement('a');
  parser.href = url;
  return parser;
};

export const Cookies = {
  get: (name) => {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  },

  set: (name, value = '', days) => {
    var d = new Date;
    d.setTime(d.getTime() + 24*60*60*1000*days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
  }
}

export const isEmail = function (value) {
  return value.match(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

export const getQueryParam = function (name) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const isLowPerformanceBrowser = function () {
  return !!isIE11();
};

export const isIE11 = function () {
  return window.navigator.userAgent.match('rv:11');
};

export const isEdge = function () {
  return window.navigator.userAgent.match('Edge/');
};

export const isIOS = function () {
  return !!(/iPad|iPhone|iPod/.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1));
};

export const isAndroid = function () {
  return !!window.navigator.userAgent.match(/android/i);
};

export const isWindows = function () {
  return window.navigator.userAgent.match('Windows');
};

export const isMac = function () {
  return window.navigator.userAgent.match('OS X') &&
    !window.navigator.userAgent.match(/iPad|iPhone/);
};

export const isChromeOS = function () {
  return window.navigator.userAgent.match('CrOS');
};

export const isLinux = function () {
  return window.navigator.userAgent.match('Linux');
};

export const showSoftwareKeyboard = function () {
  return isIOS() || isAndroid();
};

export const copyTextToClipboard = (nodeToCopy, successCallback = ()=>{}, errorCallback = ()=>{}) => {
  try {
    const selection = window.getSelection(),
      range = document.createRange();

    nodeToCopy.fastShow()
    selection.removeAllRanges();
    range.selectNodeContents(nodeToCopy[0]);
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    nodeToCopy.fastHide()
    successCallback()
  } catch(e) {
    errorCallback()
  }

  // Maybe someday we can use the Clipboard API (when it's more supported)
  // window.navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
  //   if (result.state === "granted" || result.state === "prompt") {
  //     try {
  //       window.navigator.clipboard.writeText(text).then(successCallback, errorCallback);
  //     } catch(e) {
  //       errorCallback()
  //     }
  //   } else {
  //     errorCallback()
  //   }
  // })
}

export const wordAtPosition = function getWordAt (str, pos) {

  // Perform type conversions.
  str = String(str);
  pos = Number(pos) >>> 0;

  // Search for the word's beginning and end.
  var left = str.slice(0, pos + 1).search(/\S+$/),
    right = str.slice(pos).search(/\s/);

  // The last word in the string is a special case.
  if (right < 0) {
    return str.slice(left);
  }

  // Return the word, using the located bounds to extract it from the string.
  return str.slice(left, right + pos);

};

export const getPikadayLocalizations = function () {
  return {
    previousMonth: 'calendar.previous_month'.t(),
    nextMonth: 'calendar.next_month'.t(),
    months: times(12,
      function (month) { return moment().month(month).format('MMMM'); }),
    weekdays: times(7,
      function (day) { return moment().day(day).format('dddd'); }),
    weekdaysShort: times(7,
      function (day) { return moment().day(day).format('ddd'); })
  };
};

const ROTn = function (text, map) {
  // Generic ROT-n algorithm for keycodes in MAP.
  var R = new String();
  var i,
    j,
    c,
    len = map.length;
  for (i = 0; i < text.length; i++) {
    c = text.charAt(i);
    j = map.indexOf(c);
    if (j >= 0) {
      c = map.charAt((j + len / 2) % len);
    }
    R = R + c;
  }
  return R;
};

export const rot47 = function (text) {
  if (FTWGLOBALS('env') !== 'production') { return text; }
  if (!text) { return text; }

  // Hides all ASCII-characters from 33 ("!") to 126 ("~").  Hence can be used
  // to obfuscate virtually any text, including URLs and emails.
  var R = new String();
  R = ROTn(
    text,
    '!"#$%&\'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~'
  );
  return R;
};

export const rot13 = function (str) {
  return str.replace(/[a-zA-Z]/g, function(char) {
    // Get the character code of the letter
    const charCode = char.charCodeAt(0);

    // If uppercase (A-Z)
    if (charCode >= 65 && charCode <= 90) {
      return String.fromCharCode(((charCode - 65 + 13) % 26) + 65);
    }
    // If lowercase (a-z)
    else if (charCode >= 97 && charCode <= 122) {
      return String.fromCharCode(((charCode - 97 + 13) % 26) + 97);
    }
  })
}

export const getTimezone = function() {
  try {
    return window.Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch(e) {
    return 'America/Los_Angeles'
  }
}

export const animateModalOverlayOpen = ({ modalView, overlaySelector = '[data-el=modal-overlay],[data-el=modal-close]', duration = 500 }) => {
  Velocity(
    modalView.$(overlaySelector),
    { opacity: [1,0] },
    {
      easing: 'easeOutSine',
      duration: duration,
      begin: () => {
        modalView.$('[data-el=modal-overlay]').fastShow('flex');
      },
      complete: () => {
        modalView.$('[data-el=modal-full]')
        .find('input[type=text]:eq(0),input[type=email]:eq(0)')
        .focus();
      }
    }
  );
}

export const animateModalOverlayClose = ({ modalView, overlaySelector = '[data-el=modal-overlay],[data-el=modal-close]', duration = 250 }) => {
  Velocity(
    modalView.$(overlaySelector),
    { opacity: [0,1] },
    {
      easing: 'easeInSine',
      duration: duration,
      complete: () => {
        modalView.$('[data-el=modal-overlay]').fastHide()
        modalView.remove();
        modalView.destroyIDE();
      }
    }
  )
}

export const animateModalOpen = ({ modalView, modalSelector = '[data-el=modal]', duration = 500, error = false }) => {
  Velocity(
    modalView.$(modalSelector),
    { translateY: [0,'-3rem'],
      opacity: [1,0]
    },
    { easing: 'easeOutCubic',
      duration: duration,
      queue: false,
      begin: () => {
        modalView.$('[data-el=modal]').fastShow('flex')
      },
      complete: () => {
        if (error) {
          // TODO: do we need this?
        }
      }
    }
  );
}

export const animateModalClose = ({ modalView, modalSelector = '[data-el=modal]', duration = 100 }) => {
  $(modalSelector).find('input').blur()
  Velocity(
    modalView.$(modalSelector),
    { translateY: '-6rem', opacity: 0 },
    {
      easing: 'easeInCubic',
      duration: duration,
      queue: false,
      complete: () => {
        modalView.$(modalSelector).fastHide()
      }
    }
  );
}

export const googleLogin = (portal, options = {}) => {
  const state = random(10000, 99999)
  Cookies.set('sso', portal, {path:'/'});
  Cookies.set('state', 'google|' + state, {path: '/'});
  if(options.teacher_id) {
    Cookies.set('sso_id', options.teacher_id, { path: '/' });
  }
  Cookies.set('join_code', options.joinCode || '', {path:'/'});
  Cookies.set('lang', FTWGLOBALS('language'), {path: '/', expires: 30});

  window.location.href = FTWGLOBALS('googleSsoUri')
}

export const microsoftLogin = (portal, options = {}) => {
  const state = random(10000, 99999)
  Cookies.set('sso', portal, { path: '/' })
  Cookies.set('state', 'microsoft|' + state, {path: '/'});
  Cookies.set('join_code', options.joinCode || '', { path: '/' })
  Cookies.set('lang', FTWGLOBALS('language'), { path: '/', expires: 30})

  window.location.href = FTWGLOBALS('microsoftSsoUri')
}

export const cleverLogin = (portal) => {
  const state = random(10000, 99999);
  Cookies.set('sso', portal, {path:'/'});
  Cookies.set('state', 'clever|' + state, {path: '/'});
  Cookies.set('lang', FTWGLOBALS('language'), {path: '/', expires: 30});

  window.location.href = FTWGLOBALS('cleverSsoUri')
}

export const classlinkLogin = (portal) => {
  const state = random(10000, 99999);
  Cookies.set('sso', portal, {path:'/'});
  Cookies.set('state', 'classlink|' + state, {path: '/'});
  Cookies.set('lang', FTWGLOBALS('language'), {path: '/', expires: 30});

  window.location.href = FTWGLOBALS('classlinkSsoUri')
}

export const generateCurriculumList = () => {
  let curriculum = {}
  forEach(FTWGLOBALS('languages'), (language) => {
    // Create an object containing arrays of all products under each language { 'en': [[x1,x2],[y1,y2]] }
    const value = FTWGLOBALS('products').filter((row) => row.language === language.id).map((row) => ([row.product_id, `${row.name}`])),
      languageName = (language.id === 'en' ? 'English (US & UK)' : language.name)
    if(value.length) {
      curriculum[languageName] = value.map((row) => ([row[0], row[1] + ` - ${languageName}`]))
    }
  })

  return curriculum
}

export const isClassicCurriculum = (productId) => {
  return productId === 'typing' || productId === 'typing_es' || productId === 'typing_br'
}
